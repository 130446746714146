
export default class BingNews {
    name: string = "";
    url: string = "";
    description: string = "";
    datePublished: string = "";
    image: BingNewsImage = new BingNewsImage();
    provider: Array<BingNewsProvider> = [];
    baseUrl: string = "";

    static selectedNews: BingNews;
}

export class BingNewsImage {
    thumbnail: Thumbnail = new Thumbnail();
}

export class BingNewsProvider {
    name: string = "";
    image: BingNewsImage = new BingNewsImage();
}

export class Thumbnail {
    contentUrl: string = "";
}