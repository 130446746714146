import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import AppClientInfo from "./common/AppClientInfo";
import FtUtil from "./core/FtUtil";


//1. 初始化设备
declare function initDevice(deviceReadyFunction: Function, resumeFunction: Function): void;
declare function getAppClientInfo(): any;

initDevice(initApp, initApp);

function initApp() {
    const clientInfo = getAppClientInfo();
    AppClientInfo.clientInfo = FtUtil.mergeObject(AppClientInfo.clientInfo, clientInfo);
    //从入口html得到客户端的信息
    renderApp();
}

function renderApp() {
    ReactDOM.render(
        <React.StrictMode>
            <App/>
        </React.StrictMode>,
        document.getElementById('root')
    );
}

