import * as React from "react";
import {ReactNode} from "react";
import {Button, IconButton, Typography} from "@material-ui/core";
import {Route, RouteComponentProps} from "react-router";
import AppClientInfo from "../common/AppClientInfo";
import {grey} from "@material-ui/core/colors";
import FtAdminFrame from "../core/FtAdminFrame";
import FtRow from "../core/FtRow";
import {adAdminMenuData, adminMenuData, channelMenuData, contentProviderMenuData, EMenuitem} from "./MainMenuData";
import ClientData from "../common/ClientData";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AdminApiService from "../service/AdminApiService";
import User from "../model/User";
import ContentProviderPage from "../contentProvider/ContentProviderPage";
import ProductPage from "../product/ProductPage";
import ProductControlPage from "../productControl/ProductControlPage";
import FtDivFlex from "../core/FtDivFlex";
import LoginPage from "../login/LoginPage";
import DailyOperationDataPage from "../dailyOperationData/DailyOperationDataPage";
import ChannelListPage from "../channel/ChannelListPage";
import FtMenuData from "../core/FtMenuData";
import GameOperationRatePage from "../gameOperationData/GameOperationRatePage";
import GameOperationDataPage from "../gameOperationData/GameOperationDataPage";
import AdvertDataSummaryListPage from "../advert/AdvertDataSummaryListPage";
import AdverDailyDataReportListPage from "../advert/AdverDailyDataReportListPage";
import ChangePasswordDialog from "../user/ChangePasswordDialog";

export const drawerWidth = 160;
export const topBarHeight = 40;

interface MainPageProp extends RouteComponentProps {

}

class MainPageState {
    openDrawer: boolean = true;
    mobileViewMode: boolean = false;
    leftDrawerMenuData: Array<FtMenuData> = [];
}

export default class MainPage extends React.Component<MainPageProp, MainPageState> {
    changePasswordDialog!: ChangePasswordDialog;

    constructor(props: any) {
        super(props);
        let openDrawer: boolean = true;
        let mobileViewMode: boolean = false;
        //根据屏幕大小默认是否显示左侧栏
        if (AppClientInfo.clientInfo.viewMode && (AppClientInfo.clientInfo.viewMode == "mobile" || AppClientInfo.clientInfo.viewMode == "pad")) {
            openDrawer = false;
            mobileViewMode = true;
        }
        this.state = {
            openDrawer: openDrawer,
            mobileViewMode: mobileViewMode,
            leftDrawerMenuData: [],
        };
        this.onClickLeftDrawerMenu = this.onClickLeftDrawerMenu.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
    }

    componentDidMount(): void {
        if (ClientData.isLogin()) {
            AdminApiService.getUserInfo((user: User) => {
                if (user && user.id && user.id > 0) {
                    if (user.role === "CHANNEL_ADMIN") {
                        this.setState({leftDrawerMenuData: channelMenuData});
                    } else if (user.role === "CONTENT_PROVIDER_ADMIN") {
                        this.setState({leftDrawerMenuData: contentProviderMenuData});
                    } else if (user.role === "AD_ADMIN") {
                        this.setState({leftDrawerMenuData: adAdminMenuData});
                    } else {
                        this.setState({leftDrawerMenuData: adminMenuData});
                    }
                    AppClientInfo.clientInfo.loginUser = user;
                    this.forceUpdate();
                }
            });
        }
    }

    render() {
        if (ClientData.isLogin()) {
            const loginUser = AppClientInfo.clientInfo.loginUser;
            let fullScreenPage = false;
            let topBar: ReactNode;
            if (!fullScreenPage) {
                topBar = <FtDivFlex style={{paddingLeft: 8, flexGrow: 100, justifyContent: "space-between"}}>
                    {!this.state.mobileViewMode && <FtRow cellVerticalSpace={0}>
                        <Typography variant={"h6"}>Mobidatas Admin</Typography>
                    </FtRow>}
                    {/*<Typography variant={"h6"}>*/}
                    {/*    老系统数据将于2023年9月26日21点停止更新，数据已经同步到新后台，请点击&nbsp;*/}
                    {/*    <a href={"https://dashboard.abdatas.com"} target={"_blank"}*/}
                    {/*       style={{color: "blanchedalmond", textDecorationLine: "underline"}}>*/}
                    {/*        https://dashboard.abdatas.com*/}
                    {/*    </a>*/}
                    {/*    &nbsp;到新后台查看，用户名密码不变。*/}
                    {/*</Typography>*/}
                    <FtRow cellVerticalSpace={0}>
                        <Button style={{color: "white", textTransform: "none"}}
                                onClick={this.onChangePassword}>用户名：{loginUser.contacts}</Button>
                        <IconButton style={{width: topBarHeight, height: topBarHeight, color: "white"}} onClick={() => {
                            ClientData.clearLocalStorageUserId();
                            window.location.reload();
                        }}><ExitToAppIcon/></IconButton>
                    </FtRow>
                </FtDivFlex>;
            }
            return <FtAdminFrame topBar={topBar} leftDrawerMenu={this.state.leftDrawerMenuData}
                                 leftDrawerOpenDefault={!this.state.mobileViewMode}
                                 leftDrawerWidth={drawerWidth}
                                 onClickLeftDrawerMenu={this.onClickLeftDrawerMenu}>
                <main>
                    {this.getMainRoute()}
                </main>
                <ChangePasswordDialog ref={(element: any) => {
                    this.changePasswordDialog = element;
                }}/>
            </FtAdminFrame>
        } else {
            return <LoginPage {...this.props} />
        }
    }

    onChangePassword() {
        this.changePasswordDialog.show();
    }

    getMainRoute() {
        if (AppClientInfo.clientInfo.loginUser !== null && AppClientInfo.clientInfo.loginUser.id !== null && AppClientInfo.clientInfo.loginUser.id > 0) {
            if (AppClientInfo.clientInfo.loginUser.role == "CHANNEL_ADMIN") {
                return <div style={{
                    backgroundColor: grey[100],
                    height: "calc(100vh - " + topBarHeight + "px)",
                    marginTop: topBarHeight,
                    marginLeft: 160
                }}>
                    <Route path="/advertDataSummary" component={AdvertDataSummaryListPage} exact={false}/>
                    <Route path="/advertDailyDataReport" component={AdverDailyDataReportListPage} exact={false}/>
                </div>
            } else if (AppClientInfo.clientInfo.loginUser.role == "CONTENT_PROVIDER_ADMIN") {
                return <div style={{
                    backgroundColor: grey[100],
                    height: "calc(100vh - " + topBarHeight + "px)",
                    marginTop: topBarHeight,
                    marginLeft: 160
                }}>
                </div>
            } else if (AppClientInfo.clientInfo.loginUser.role == "AD_ADMIN") {
                return <div style={{
                    backgroundColor: grey[100],
                    height: "calc(100vh - " + topBarHeight + "px)",
                    marginTop: topBarHeight,
                    marginLeft: 160
                }}>
                    <Route path="/productPage" component={ProductPage} exact={false}/>
                    <Route path="/channelPage" component={ChannelListPage} exact={false}/>
                    <Route path="/advertDataSummary" component={AdvertDataSummaryListPage} exact={false}/>
                    <Route path="/advertDailyDataReport" component={AdverDailyDataReportListPage} exact={false}/>
                </div>
            } else {
                return <div style={{
                    backgroundColor: grey[100],
                    height: "calc(100vh - " + topBarHeight + "px)",
                    marginTop: topBarHeight,
                    marginLeft: 160
                }}>
                    <Route path="/contentProviderPage" component={ContentProviderPage} exact={false}/>
                    <Route path="/productPage" component={ProductPage} exact={false}/>
                    <Route path="/productControl" component={ProductControlPage} exact={false}/>
                    <Route path="/dailyOperationDataPage" component={DailyOperationDataPage} exact={false}/>
                    <Route path="/gameOperationDataPage" component={GameOperationDataPage} exact={false}/>
                    <Route path="/gameOperationRatePage" component={GameOperationRatePage} exact={false}/>
                    <Route path="/channelPage" component={ChannelListPage} exact={false}/>
                    <Route path="/advertDataSummary" component={AdvertDataSummaryListPage} exact={false}/>
                    <Route path="/advertDailyDataReport" component={AdverDailyDataReportListPage} exact={false}/>
                </div>
            }
        } else {
            return null;
        }
    }

    private onClickLeftDrawerMenu(menuId: string) {
        switch (menuId as EMenuitem) {
            case EMenuitem.CP_ADMIN:
                this.props.history.push("/contentProviderPage");
                return;
            case EMenuitem.PRODUCT_ADMIN:
                this.props.history.push("/productPage");
                return;
            case EMenuitem.PRODUCT_CONTROL_ADMIN:
                this.props.history.push("/productControl");
                return;
            case EMenuitem.DAILY_OPERATION_DATA:
                this.props.history.push("/dailyOperationDataPage");
                return;
            case EMenuitem.CHANNEL_ADMIN:
                this.props.history.push("/channelPage");
                return;
            case EMenuitem.GAME_OPERATION_DATA:
                this.props.history.push("/gameOperationDataPage");
                return;
            case EMenuitem.GAME_OPERATION_RATE:
                this.props.history.push("/gameOperationRatePage");
                return;
            case EMenuitem.ADVERT_DATA_SUMMARY:
                this.props.history.push("/advertDataSummary");
                return;
            case EMenuitem.ADVERT_DAILY_DATA_REPORT:
                this.props.history.push("/advertDailyDataReport");
                return;
        }
    }
}
