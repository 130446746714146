import HttpService from "./HttpService";
import User from "../model/User";
import ApiResponse from "../core/ApiResponse";
import JtPagedList from "../core/JtPagedList";
import ContentProvider from "../model/ContentProvider";
import Product from "../model/Product";
import ProductControl from "../model/ProductControl";
import DailyOperationData from "../model/DailyOperationData";
import Channel from "../model/Channel";
import GameOperationData from "../model/GameOperationData";
import AdvertData from "../model/AdvertData";
import FtUtil from "../core/FtUtil";
import {FileUploadResult} from "../core/FtFileUploadComp";

export default class AdminApiService extends HttpService {

    static getUserInfo(successFunction: (user: User) => void, failFunction ?: (error: ApiResponse) => void): void {
        HttpService.postFormData("/admin/api/getUserInfo", {}, successFunction, failFunction ? failFunction : HttpService.handleError);
    }

    static getContentProviderPagedList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                                       pageNo: number, pageSize: number, successFunction: (orderList: JtPagedList<ContentProvider>) => void): void {
        HttpService.search("/admin/api/getContentProviderPagedList", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize, successFunction, HttpService.handleError);
    }

    static saveContentProvider(contentProvider: ContentProvider, successFunction: (contentProvider: ContentProvider) => void): void {
        HttpService.postJson("/admin/api/saveContentProvider", contentProvider, successFunction, HttpService.handleError);
    }

    static getProductPagedList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                               pageNo: number, pageSize: number, successFunction: (orderList: JtPagedList<Product>) => void): void {
        HttpService.search("/admin/api/getProductPagedList", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize, successFunction, HttpService.handleError);
    }

    static saveProduct(product: Product, successFunction: (product: Product) => void): void {
        HttpService.postJson("/admin/api/saveProduct", product, successFunction, HttpService.handleError);
    }

    static saveProductNote(productCode: string, productNote: string, successFunction: () => void): void {
        const params = {productCode: productCode, productNote: productNote};
        HttpService.postFormData("/admin/api/saveProductNote", params, successFunction, HttpService.handleError);
    }

    static getProductControlPagedList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                                      pageNo: number, pageSize: number, successFunction: (orderList: JtPagedList<ProductControl>) => void): void {
        HttpService.search("/admin/api/getProductControlPagedList", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize, successFunction, HttpService.handleError);
    }

    static saveProductControl(productControl: ProductControl, successFunction: (productControl: ProductControl) => void): void {
        HttpService.postJson("/admin/api/saveProductControl", productControl, successFunction, HttpService.handleError);
    }

    static getDailyOperationDataPagedList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                                          pageNo: number, pageSize: number, successFunction: (orderList: JtPagedList<DailyOperationData>) => void): void {
        HttpService.search("/admin/api/getDailyOperationDataPagedList", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize, successFunction, HttpService.handleError);
    }

    static saveDailyOperationData(file: any, successFunction: (data: any) => void, progressFunction: (percent: number) => void, failFunction: (error: any) => void): void {
        HttpService.upload("/admin/api/saveDailyOperationData", file, successFunction, progressFunction, failFunction);
    }

    static getChannelPagedList(searchKeyword: string, filterColumns: Map<string, any>,
                               pageNo: number, pageSize: number, successFunction: (channePaged: JtPagedList<Channel>) => void): void {
        HttpService.search("/admin/api/getChannelPagedList", searchKeyword, filterColumns, null, null, pageNo, pageSize, successFunction, HttpService.handleError);
    }

    static saveChannel(channel: Channel, successFunction: (channel: Channel) => void): void {
        HttpService.postJson("/admin/api/saveChannel", channel, successFunction, HttpService.handleError);
    }

    static getGameOperationDataPagedList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                                         pageNo: number, pageSize: number, successFunction: (orderList: JtPagedList<GameOperationData>) => void): void {
        HttpService.search("/admin/api/getGameOperationDataPagedList", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize, successFunction, HttpService.handleError);
    }

    static exportExcel(lastdays: number, successFunction: (res: any) => void): void {
        HttpService.get("/admin/excel/exportExcel", {lastdays: lastdays}, successFunction, HttpService.handleError);
    }

    static saveGameOperationData(file: any, successFunction: (data: any) => void, progressFunction: (percent: number) => void, failFunction: (error: any) => void): void {
        HttpService.upload("/admin/api/saveGameOperationData", file, successFunction, progressFunction, failFunction);
    }

    static calculationGameDataMoney(successFunction: (msg: string) => void): void {
        HttpService.get("/admin/api/calculationGameDataMoney", {}, successFunction, HttpService.handleError);
    }

    static getGameOperationDataSummary(filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                                       pageNo: number, pageSize: number, successFunction: (orderList: JtPagedList<GameOperationData>) => void): void {
        HttpService.search("/admin/api/getGameOperationDataSummary", "", filterColumns, sortPropertyName, sortDirection, pageNo, pageSize, successFunction, HttpService.handleError);
    }

    static getAdvertDataPagedList(searchKeyword: string, filterColumns: Map<string, any>,
                                  pageNo: number, pageSize: number, successFunction: (channePaged: JtPagedList<AdvertData>) => void): void {
        HttpService.search("/admin/api/getAdvertDataPagedList", searchKeyword, filterColumns, null, null, pageNo, pageSize, successFunction, HttpService.handleError);
    }

    static getAdvertDataSummaryPaged(searchKeyword: string, filterColumns: Map<string, any>,
                                     pageNo: number, pageSize: number, successFunction: (channePaged: JtPagedList<AdvertData>) => void): void {
        HttpService.search("/admin/api/getAdvertDataSummaryPaged", searchKeyword, filterColumns, null, null, pageNo, pageSize, successFunction, HttpService.handleError);
    }

    static getChannelCodeNameMap(successFunction: (data: Map<string, string>) => void): void {
        HttpService.get("/admin/api/getChannelCodeNameMap", {}, (response: any) => {
            successFunction(FtUtil.objectToMap(response));
        }, HttpService.handleError);
    }

    static getLastImportDatetime(successFunction: (datetime: string) => void): void {
        HttpService.get("/admin/api/getLastImportDatetime", {}, successFunction, HttpService.handleError);
    }

    static getProductCodeNameMap(attr: string, successFunction: (data: Map<string, string>) => void): void {
        HttpService.get("/admin/api/getProductCodeNameMap", {attr: attr}, (response: any) => {
            successFunction(FtUtil.objectToMap(response));
        }, HttpService.handleError);
    }

    static getProductCodeCpNameMap(successFunction: (data: Map<string, string>) => void): void {
        HttpService.get("/admin/api/getProductCodeCpNameMap", {}, (response: any) => {
            successFunction(FtUtil.objectToMap(response));
        }, HttpService.handleError);
    }

    static changePassword(oldPassword: string, newPassword1: string, successFunction: (data: string) => void, failFunction: (error: any) => void): void {
        let params = {oldPassword: oldPassword, newPassword: newPassword1};
        HttpService.postFormData("/admin/api/changePassword", params, successFunction, failFunction);
    }

    static saveAdvertData(importDataList: Array<AdvertData>, successFunction: (data: ApiResponse) => void): void {
        HttpService.postJson("/admin/api/saveAdvertData", importDataList, successFunction, HttpService.handleError);
    }

    static importProductFile(file: any, successFunction: (data: FileUploadResult) => void, progressFunction: (percent: number) => void, failFunction: (error: any) => void) {
        HttpService.upload("/admin/api/importProductFile", file, successFunction, progressFunction, failFunction);
    }
}
