import * as React from "react";

export default class WatchableData {
    static watchers: { [key: string]: Array<((newData: any, oldData?: any) => void) | React.Component>; } = {};

    static watch(dataName: string, onChangeFunction: ((newData: any, oldData?: any) => void) | React.Component) {
        if (this.watchers[dataName] == null)
            this.watchers[dataName] = [];
        this.watchers[dataName].push(onChangeFunction);
    }

    static unWatch(dataName: string, watcher: ((newData: any, oldData?: any) => void) | React.Component<any, any>) {
        if (this.watchers[dataName] != null) {
            this.watchers[dataName].forEach((onChangeFunction: ((newData: any, oldData?: any) => void) | React.Component,
                                             index: number) => {
                if (onChangeFunction === watcher) {
                    this.watchers[dataName].splice(index, 1);
                }
            });
        }
    }

    static fireDataChange(dataName: string, newData: any, oldData?: any) {
        if (this.watchers[dataName] != null) {
            this.watchers[dataName].forEach((onChangeFunction: ((newData: any, oldData?: any) => void) | React.Component,
                                             index: number) => {
                if (onChangeFunction instanceof React.Component) {
                    (onChangeFunction as React.Component).forceUpdate();
                } else
                    onChangeFunction(newData, oldData);
            });
        }
    }

    static watchByConponent(dataName: string, comp: React.Component<any, any>) {
        this.watch(dataName, comp);
    }

    static unWatchByConponent(dataName: string, comp: React.Component<any, any>) {
        this.unWatch(dataName, comp);
    }
}