import * as React from "react";
import {ReactNode} from "react";
import {InputAdornment, TextField} from "@material-ui/core";
import {BaseTextFieldProps} from "@material-ui/core/TextField";
import {InputProps as StandardInputProps} from "@material-ui/core/Input/Input";
import {OutlinedInputProps} from "@material-ui/core/OutlinedInput";
import {FilledInputProps} from "@material-ui/core/FilledInput";

export interface FtTextFieldProp extends BaseTextFieldProps {
    helperText?: string;
    type?: "text" | "email" | "password" | "chinese" | "ascii" | "integer" | "float" | "phone" | "currency"
    length?: number;
    startAdornment?: ReactNode;
    endAdornment?: ReactNode;
    originalValue?: unknown;
    variant?: "standard" | "filled" | "outlined";
    InputProps?: Partial<StandardInputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps>;
    inputProps?: StandardInputProps["inputProps"] | FilledInputProps["inputProps"] | OutlinedInputProps["inputProps"];
    onBlur?: StandardInputProps['onBlur'] | FilledInputProps["onBlur"] | OutlinedInputProps["onBlur"];
    onChange?: StandardInputProps['onChange'] | FilledInputProps["onChange"] | OutlinedInputProps["onChange"];
    onFocus?: StandardInputProps['onFocus'] | FilledInputProps["onFocus"] | OutlinedInputProps["onFocus"];
}

export class FtTextFieldState {
    errorMessage: string = "";
}

const regexpEmail = new RegExp("^[\\w-]+@([\\w-]+\\.)+[\\w-]+$");
const regexpPassword = new RegExp('^[a-zA-Z0-9.!#$&*+?_@-]+$');
const regexpAscii = new RegExp('^[a-zA-Z0-9,.#&*\\s-]*$');
const regexpInteger = new RegExp("^[-+]?[0-9]+$");
const regexpFloat = new RegExp("^[+-]?[0-9]*(.[0-9]*)?$");
const regexpPhone = new RegExp("^[+]?[0-9()-]+$");
// const regexpCurrency = new RegExp("^\\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9]?[0-9]?)?$");
const regexpCurrency= new RegExp("^[+-]?([0-9]*)?(.[0-9]?[0-9]?)?$");

const charInteger = new RegExp("^[-+]?[0-9]*$");
const charFloat = new RegExp("^[-+]?[0-9.]*$");
const charCurrency = new RegExp("^[-+]?[0-9.]*$");
const charPhone = new RegExp("^[+]?[0-9()-]*$");

export default class FtTextField extends React.Component<FtTextFieldProp, FtTextFieldState> {
    constructor(props: any) {
        super(props);
        this.state = {errorMessage: ""};
        this.validate = this.validate.bind(this);
        this.onChangeCapture = this.onChangeCapture.bind(this);
        this.onChange = this.onChange.bind(this);
        this.formatValue=this.formatValue.bind(this);
    }

    render() {
        let error: boolean = false;
        let helperText: string | undefined = this.props.helperText ? this.props.helperText : "";
        if (this.state.errorMessage.length > 0) {
            helperText = this.state.errorMessage;
            error=true;
        }
        const baseTextFieldProps: BaseTextFieldProps = this.props;
        const InputProps: any = {};
        if (this.props.startAdornment)
            InputProps.startAdornment = <InputAdornment position="start">{this.props.startAdornment}</InputAdornment>;
        if (this.props.endAdornment)
            InputProps.endAdornment = <InputAdornment position="end">{this.props.endAdornment}</InputAdornment>;
        let variant: any = "standard";
        if (this.props.variant)
            variant = this.props.variant;
        const value=this.formatValue(this.props.value);
        if (this.props.originalValue && this.props.value != this.props.originalValue)
            return <TextField {...baseTextFieldProps} value={value}  error={error} helperText={helperText} variant={"filled"}
                              InputProps={InputProps} onChange={this.onChange} onChangeCapture={this.onChangeCapture}
                              onBlur={this.props.onBlur} onFocus={this.props.onFocus}
                              inputProps={{style: {}}}>
            </TextField>;
        else
            return <TextField {...baseTextFieldProps} value={value} error={error} helperText={helperText} variant={variant}
                              InputProps={InputProps} onChange={this.onChange} onChangeCapture={this.onChangeCapture}
                              onBlur={this.props.onBlur} onFocus={this.props.onFocus}
                              inputProps={{style: {}}}>
            </TextField>;
    }

    private formatValue(value: unknown) {
        if(value!=null){
            if(typeof value=="number"&&this.props.type != null && (this.props.type == "float"||this.props.type == "currency")){
                const text:string=""+value;
                if(text.indexOf(".")<0)
                    return value+".";
            }
        }
        return value;
    }
    private onChange(event: any) {
        const value = event.target.value;
        if (value != null && (value + "").length > 0) {
            let errorMessage: string = this.validate(value);
            if (errorMessage != null && errorMessage.length > 0) {
                this.setState({errorMessage: errorMessage});
            }else
                this.setState({errorMessage: ""});
        }
        if (this.props.onChange)
            this.props.onChange(event);
    }

    private onChangeCapture(event: any) {
        let message = "";
        const value = event.target.value;
        if (this.props.type != null && this.props.type == "email") {
        } else if (this.props.type != null && this.props.type == "password") {
        } else if (this.props.type != null && this.props.type == "ascii") {
        } else if (this.props.type != null && this.props.type == "integer") {
            if (isNaN(parseInt(value)))
                message = "请输入整数";
        } else if (this.props.type != null && this.props.type == "float") {
            if (isNaN(parseFloat(value)))
                message = "请输入浮点数";
        } else if (this.props.type != null && this.props.type == "phone") {
            if (!charPhone.test(value))
                message = "请输入有效的手机号";
        } else if (this.props.type != null && this.props.type == "currency") {
            console.log(parseFloat(value));
            if (isNaN(parseFloat(value)))
                message = "请输入有效的金额";
        }
        if (message != null && message.length > 0) {
            event.preventDefault();
            event.stopPropagation();
            return false;
        } else
            return true;
    }

    validate(value: string): string {
        let message: string = "";
        if(value!=null&&value.length>0) {
            if (this.props.type != null && this.props.type == "email") {
                if (!regexpEmail.test(value))
                    message = "Email格式不正确";
            } else if (this.props.type != null && this.props.type == "password") {
                if (!regexpPassword.test(value) || value.length < 6)
                    message = "请输入6位以上英文字母、数字或#$&*+?_@-.!";
            } else if (this.props.type != null && this.props.type == "ascii") {
                if (!regexpAscii.test(value))
                    message = "请输入英文字母、数字、空格或,.#&*-";
            } else if (this.props.type != null && this.props.type == "integer") {
                if (!regexpInteger.test(value))
                    message = "请输入整数";
            } else if (this.props.type != null && this.props.type == "float") {
                if (!regexpFloat.test(value))
                    message = "请输入浮点数";
            } else if (this.props.type != null && this.props.type == "phone") {
                if (!regexpPhone.test(value) || value.length < 10)
                    message = "请输入10位以上电话号码";
            } else if (this.props.type != null && this.props.type == "currency") {
                if (!regexpCurrency.test(value))
                    message = "金额格式不正确";
            }
            if (this.props.length && this.props.length > 0 && value.length > this.props.length)
                message = "长度不超过" + this.props.length + "个字符";
        }
        return message;
    }

}