import User from "./User";

export default class ContentProvider {
    id: number = 0;
    code: string = "";
    name: string = "";
    returnProportion: number = 0;
    divideProportion: number = 0;
    returnProportionText: string = "";
    divideProportionText: string = "";
    url: string = "";
    userName: string = "";
    userPassword: string = "";
    createDatetimeText: string = "";
    updateDatetimeText: string = "";
    updateUserName: string = "";
    user: User = new User();
}
