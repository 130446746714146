import ApiResponse from "./ApiResponse";
import {} from 'jquery';

export default class AjaxHelper {
    static setting = {};

    static get(uri: string, params: any, successFunction: (data:any, textStatus:string, request:any)=>void, errorFunction?: Function, headers?: any): void {
        this.request("GET", uri, params, successFunction, errorFunction, headers);
    }

    static postFormData(uri: string, params: any, successFunction: (data:any, textStatus:string, request:any)=>void, errorFunction?: Function, headers?: any): void {
        this.request("POST", uri, params, successFunction, errorFunction, headers);
    }

    static postJson(uri: string, jsonObject: any, successFunction: (data:any, textStatus:string, request:any)=>void, errorFunction?: Function, headers?: any): void {
        this.request("POST", uri, JSON.stringify(jsonObject), successFunction, errorFunction, headers, "json", "application/json; charset=utf-8");
    }

    static request(method: string, uri: string, params: any, successFunction: (data:any, textStatus:string, request:any)=>void, errorFunction?: Function, headers?: any, dataType?: string, contentType?: string): void {
        $.ajax({
            url: uri,
            method: method,
            data: params,
            headers: headers,
            dataType: dataType,
            contentType: contentType,
            success: successFunction,
            error: function (errMsg, textStatus: string, errorThrown: string) {
                if(errMsg) {
                    console.log(JSON.stringify(errMsg));
                }
                let apiResponse: ApiResponse = new ApiResponse();
                if (errMsg.responseJSON) {
                    apiResponse = errMsg.responseJSON;
                } else {
                    if (errMsg.responseText && errMsg.responseText.length > 0) {
                        apiResponse.message = errMsg.responseText;
                    } else if (errorThrown && errorThrown.length > 0) {
                        apiResponse.message = errorThrown;
                    } else if (textStatus && textStatus.length > 0) {
                        apiResponse.message = textStatus;
                        if (errMsg.status)
                            apiResponse.message += " " + errMsg.status;
                    } else {
                        apiResponse.message = "未知错误";
                    }
                }
                if (errorFunction)
                    errorFunction(apiResponse);
            }
        });
    }

    static uploadFile(url: string,formData: FormData,  successFunction: (data: any) => void, progressFunction: (e: any) => void,
                      failFunction: (error: any) => void, headers?: any) {
        $.ajax({
            url: url,
            type: 'POST',
            data: formData,
            headers: headers,
            processData: false,
            contentType: false,
            success: successFunction,
            error: failFunction,
            xhr: function () {
                var myXhr = new XMLHttpRequest();
                if (myXhr.upload) {
                    myXhr.upload.addEventListener('progress', progressFunction, false);
                }
                return myXhr;
            }
        });
    }


}