import * as React from "react";
import {ChangeEvent} from "react";
import {Button, Link, Typography} from "@material-ui/core";
import {RouteComponentProps} from "react-router";
import FtSnackbars from "../core/FtSnackbars";
import FtToast from "../core/FtToast";
import FtPanel from "../core/FtPanel";
import FtGrid from "../core/FtGrid";
import FtRow from "../core/FtRow";
import FtTextField from "../core/FtTextField";
import ClientData from "../common/ClientData";
import OpenApiService from "../service/OpenApiService";

interface LoginPageProp extends RouteComponentProps {
}


export class LoginPageState {
    loginName: string = "";
    password: string = "";
}


export default class LoginPage extends React.Component<LoginPageProp, LoginPageState> {

    constructor(props: any) {
        super(props);
        this.state = {loginName: "", password: ""};
        this.onLoginClick = this.onLoginClick.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount(): void {

    }

    onLoginClick(): void {
        if (this.state.loginName == "") {
            FtSnackbars.showText("请输入账号", 6000);
            return;
        };
        if (this.state.password == "") {
            FtSnackbars.showText("请输入密码", 6000);
            return;
        }
        FtToast.showToastLoading("正在登录", 10000);
        OpenApiService.login(this.state.loginName, this.state.password, (userId: string)=>{
            FtToast.closeToast();
            ClientData.saveUserIdToLocalStorage(userId);
            // window.location.reload();
            let redirectUrl: string = window.location.href;
            let indexOf = "/#/";
            if (redirectUrl.indexOf(indexOf) > 0) {
                redirectUrl = redirectUrl.substring(0, redirectUrl.indexOf(indexOf));
            }
            window.location.href = redirectUrl + "/";
        })
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
        if (event.target.id == "loginName") {
            this.setState({loginName: event.target.value});
        } else if (event.target.id == "password") {
            this.setState({password: event.target.value});
        }
    }

    render() {
        return <div style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed"
        }}>
            <div>
                <FtPanel elevation={3} marginHorizontal={16} marginVertical={16} style={{width: 480, position: "relative"}}>
                    <FtGrid marginVertical={16} marginHorizontal={16}>
                        <FtRow cellWidthS={"1"} cellVerticalSpace={32} cellHorizontalAlign={"center"}>
                            <Typography variant={"h4"} style={{paddingTop: 14, paddingLeft: 24}}>Mobidatas Admin</Typography>
                            <FtTextField id="loginName" type={"text"} label="账号" onChange={this.onChange} required={true}
                                         style={{width: "100%"}} variant={"outlined"}/>
                            <FtTextField id="password" type={"password"} label="密码" onChange={this.onChange} required={true}
                                         style={{width: "100%"}} variant={"outlined"}/>
                            <Button onClick={this.onLoginClick} color="primary" style={{width: "100%"}}
                                    variant={"contained"}>登录</Button>
                        </FtRow>
                    </FtGrid>
                </FtPanel>
                {/*<div style={{marginTop: 32, textAlign: "center"}}>*/}
                {/*    <Link href={"https://beian.miit.gov.cn/"} target={"_blank"}>京ICP备2021000388号</Link>*/}
                {/*</div>*/}
            </div>
        </div>
    }
}
