import * as React from "react";
import {HTMLAttributes, ReactNode} from "react";
import {Paper, Typography} from "@material-ui/core";
import FtUtil from "./FtUtil";

export interface FtPanelProp extends HTMLAttributes<HTMLDivElement> {
    panelHeader?: string | ReactNode;
    elevation?: number;
    marginHorizontal?: 0 | 2 | 4 | 8 | 12 | 16 | 32;
    marginVertical?: 0 | 2 | 4 | 8 | 12 | 16 | 32;
}

export default class FtPanel extends React.Component<FtPanelProp, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        let style={
            marginTop: this.props.marginVertical,
            marginBottom: this.props.marginVertical,
            marginLeft: this.props.marginHorizontal,
            marginRight: this.props.marginHorizontal
        };
        if(this.props.style)
            style=FtUtil.mergeObject(style,this.props.style);
        return <Paper elevation={this.props.elevation} style={style}>
            {this.props.panelHeader && typeof this.props.panelHeader === "string" &&
            <div style={{paddingLeft:8,paddingRight:8,paddingTop:8}}><Typography variant={"h6"}>{this.props.panelHeader}</Typography></div>}
            {this.props.panelHeader && typeof this.props.panelHeader !== "string" && this.props.panelHeader}
            {this.props.children}
        </Paper>;
    }
}