import User from "./User";

export default class Channel {
    id: number = 0;
    name: string = "";
    code: string = "";
    divideProportion: number = 0;
    deductionsProportion: number = 0;
    status: string = "";
    userId: number = 0;
    user: User = new User();
}
