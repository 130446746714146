import * as React from "react";
import JtUtil from "./FtUtil";
import JtState from "./JtState";

export class JtComponentState {
    stateKey?: string = "";
}

export default class JtComponent<P, S extends JtComponentState> extends React.Component<P, S> {
    constructor(props: any) {
        super(props);
    }
    /**
     * 将当前的state缓存
     */
    componentWillUnmount() {
        if (this.state && this.state.stateKey && this.state.stateKey.length > 0) {
            JtState.put(this.state.stateKey, this.state);
        }
    }
    popStateCache(){
        //如果之前有缓存的state，则使用缓存的state
        if (this.state && this.state.stateKey && this.state.stateKey.length > 0) {
            const cachedState = JtState.pop(this.state.stateKey);
            if (cachedState) {
                this.state = JtUtil.mergeObject(this.state, cachedState);
            }
        }
    }
}