import * as React from "react";
import {ReactNode} from "react";
import {RouteComponentProps} from "react-router";
import JtDataTable, {JtDataTableAdapter, JtDataTableColumnProps} from "../core/JtDataTable";
import FtToast from "../core/FtToast";
import JtState from "../core/JtState";
import AdminApiService from "../service/AdminApiService";
import JtPagedList from "../core/JtPagedList";
import FtRow from "../core/FtRow";
import AdvertData from "../model/AdvertData";
import AppClientInfo from "../common/AppClientInfo";
import {Button, IconButton, TextField, Typography} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import Channel from "../model/Channel";
import {ArrowBack} from "@material-ui/icons";
import FtSnackbars from "../core/FtSnackbars";
import {exportExcel} from "../core/ExcelUtil";
import AdvertDataImportDialog from "./AdvertDataImportDialog";
import FtUtil from "../core/FtUtil";

interface AdvertDataSummaryListPageProp extends RouteComponentProps {

}

export class AdvertDataSummaryListPageState {
    tableAdapter: TableAdapter = new TableAdapter();
    columnProps: Array<JtDataTableColumnProps> = [];
    footerColumnProps: Array<string | ReactNode> = [];
    channelList: Array<Channel> = [];
    channelNameMap: Map<string, string> = new Map<string, string>();
    lastImportDatetime?: string;
}

class TableAdapter extends JtDataTableAdapter<AdvertData> {
    channelCode: string | undefined = undefined;

    loadData(): void {
        FtToast.showToastLoading();
        if (this.channelCode) {
            this.filterColumns.set("channelCode", this.channelCode);
        }
        AdminApiService.getAdvertDataSummaryPaged(this.keyword, this.filterColumns, this.pagedList.pageNo, this.pagedList.pageSize,
            (result: JtPagedList<AdvertData>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}

export default class AdvertDataSummaryListPage extends React.Component<AdvertDataSummaryListPageProp, AdvertDataSummaryListPageState> {
    importDialog!: AdvertDataImportDialog;

    constructor(props: any) {
        super(props);
        let tableAdapter: TableAdapter;
        const cacheState: AdvertDataSummaryListPageState = JtState.pop("AdvertDataSummaryListPageState");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new TableAdapter();
            tableAdapter.pagedList.pageSize = 100;
            if (AppClientInfo.clientInfo.loginUser.role === "CHANNEL_ADMIN") {
                tableAdapter.channelCode = AppClientInfo.clientInfo.loginUser.objectCode;
            }
        }
        tableAdapter.onUpdateData = ((pagedList: JtPagedList<AdvertData>) => {
            let gaDailyActive = 0;
            let viewsCount = 0;
            let clickCount = 0;
            let estimatedIncome = 0;
            let channelEstimatedIncome = 0;
            pagedList.data.forEach((data: AdvertData) => {
                gaDailyActive += data.gaDailyActive;
                viewsCount += data.viewsCount;
                clickCount += data.clickCount;
                estimatedIncome += data.estimatedIncome;
                channelEstimatedIncome += data.channelEstimatedIncome;
            });
            let footerColumnProps: Array<string> = [];
            if (AppClientInfo.clientInfo.loginUser.role == "CHANNEL_ADMIN") {
                footerColumnProps = ["Total", gaDailyActive + "", viewsCount + "", clickCount + "", FtUtil.toCurrency(channelEstimatedIncome), "", "", ""];
            } else {
                footerColumnProps = ["Total", "", gaDailyActive + "", viewsCount + "", clickCount + "", FtUtil.toCurrency(estimatedIncome), "", "", ""];
            }
            this.setState({footerColumnProps: footerColumnProps}, this.forceUpdate);
        });
        this.state = {
            tableAdapter: tableAdapter,
            columnProps: [],
            channelList: [],
            footerColumnProps: [],
            channelNameMap: new Map<string, string>()
        };
        this.getFilterPanel = this.getFilterPanel.bind(this);
        this.loadChannel = this.loadChannel.bind(this);
        this.leftNode = this.leftNode.bind(this);
        this.onFinishUpload = this.onFinishUpload.bind(this);
        this.loadChannelMap = this.loadChannelMap.bind(this);
        this.getChannelColumn = this.getChannelColumn.bind(this);
        this.getAdminColumn = this.getAdminColumn.bind(this);
        this.loadLastImportDatetime = this.loadLastImportDatetime.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
        this.loadChannel();
        this.loadChannelMap();
        this.loadLastImportDatetime();
        if (AppClientInfo.clientInfo.loginUser.role == "CHANNEL_ADMIN") {
            this.setState({columnProps: this.getChannelColumn()});
        } else {
            this.setState({columnProps: this.getAdminColumn()});
        }
    }

    getAdminColumn() {
        const columnProps: Array<JtDataTableColumnProps> = [];
        const datetime: JtDataTableColumnProps = new JtDataTableColumnProps("datetime", "Date");
        columnProps.push(datetime);
        const channelName: JtDataTableColumnProps = new JtDataTableColumnProps("channelName", "Channel name");
        channelName.renderCell = (rowData: object) => {
            const advertData = rowData as AdvertData;
            return this.state.channelNameMap.get(advertData.channelCode);
        };
        columnProps.push(channelName);
        const gaDailyActive: JtDataTableColumnProps = new JtDataTableColumnProps("gaDailyActive", "GA dau");
        columnProps.push(gaDailyActive);
        const viewsCount: JtDataTableColumnProps = new JtDataTableColumnProps("viewsCount", "Impressions");
        columnProps.push(viewsCount);
        const clickCount: JtDataTableColumnProps = new JtDataTableColumnProps("clickCount", "Clicks");
        columnProps.push(clickCount);
        const estimatedIncome: JtDataTableColumnProps = new JtDataTableColumnProps("estimatedIncome", "Ad revenue($)");
        columnProps.push(estimatedIncome);
        const ctr: JtDataTableColumnProps = new JtDataTableColumnProps("ctr", "CTR");
        columnProps.push(ctr);
        const cpc: JtDataTableColumnProps = new JtDataTableColumnProps("cpc", "CPC");
        columnProps.push(cpc);
        const ecpm: JtDataTableColumnProps = new JtDataTableColumnProps("ecpm", "ECPM");
        columnProps.push(ecpm);
        return columnProps;
    }

    getChannelColumn() {
        const columnProps: Array<JtDataTableColumnProps> = [];
        const datetime: JtDataTableColumnProps = new JtDataTableColumnProps("datetime", "Date");
        columnProps.push(datetime);
        const gaDailyActive: JtDataTableColumnProps = new JtDataTableColumnProps("gaDailyActive", "GA dau");
        columnProps.push(gaDailyActive);
        const viewsCount: JtDataTableColumnProps = new JtDataTableColumnProps("viewsCount", "Impressions");
        columnProps.push(viewsCount);
        const clickCount: JtDataTableColumnProps = new JtDataTableColumnProps("clickCount", "Clicks");
        columnProps.push(clickCount);
        const estimatedIncome: JtDataTableColumnProps = new JtDataTableColumnProps("channelEstimatedIncome", "Ad revenue($)");
        columnProps.push(estimatedIncome);
        const ctr: JtDataTableColumnProps = new JtDataTableColumnProps("ctr", "CTR");
        columnProps.push(ctr);
        const cpc: JtDataTableColumnProps = new JtDataTableColumnProps("channelCpc", "CPC");
        columnProps.push(cpc);
        const ecpm: JtDataTableColumnProps = new JtDataTableColumnProps("channelEcpm", "ECPM");
        columnProps.push(ecpm);
        return columnProps;
    }

    loadChannel(): void {
        AdminApiService.getChannelPagedList("", new Map<string, any>(), 0, 99999, (result: JtPagedList<Channel>) => {
            FtToast.closeToast();
            this.setState({channelList: result.data});
        });
    }

    loadChannelMap(): void {
        AdminApiService.getChannelCodeNameMap((data: Map<string, string>) => {
            this.setState({channelNameMap: data});
        })
    }

    loadLastImportDatetime(): void {
        AdminApiService.getLastImportDatetime((datetime: string) => {
            this.setState({lastImportDatetime: datetime});
        });
    }

    render() {
        return <div>
            <JtDataTable title={"Data summary"} columnProps={this.state.columnProps}
                         filterPanel={this.getFilterPanel()}
                         footerColumnProps={this.state.footerColumnProps}
                         leftNode={this.leftNode()}
                         rightNode={this.rightNode()}
                         cellPadding={8}
                         adapter={this.state.tableAdapter}/>
            <AdvertDataImportDialog ref={(element: any) => {
                this.importDialog = element;
            }}/>
        </div>
    }

    leftNode(): ReactNode {
        return <FtRow cellHorizontalSpace={16}>
            <IconButton onClick={() => {
                this.props.history.goBack()
            }}><ArrowBack/></IconButton>
            {AppClientInfo.clientInfo.loginUser.role === "ADMIN" &&
            <Button color={"primary"} variant={"contained"}
                    style={{textTransform: "none"}}
                    onClick={() => this.importDialog.show(this.onFinishUpload)}>
                Import
            </Button>}
            <Button color={"secondary"} variant={"contained"}
                    style={{textTransform: "none"}}
                    onClick={() => {
                        if (AppClientInfo.clientInfo.loginUser.role == "CHANNEL_ADMIN") {
                            this.onExportChannelExcel();
                        } else {
                            this.onExportAdminExcel();
                        }
                    }}>Export
            </Button>
        </FtRow>
    }

    rightNode(): ReactNode {
        return <Typography variant="h6" style={{marginRight: 16}}>
            {"上次导入时间: " + this.state.lastImportDatetime}
        </Typography>
    }

    onExportAdminExcel() {
        let headers: any[] = [
            {key: 'datetime', title: 'Date'},
            {key: 'channelName', title: 'Channel name'},
            {key: 'gaDailyActive', title: 'GA dau'},
            {key: 'viewsCount', title: 'Impressions'},
            {key: 'clickCount', title: 'Clicks'},
            {key: 'estimatedIncome', title: 'Ad revenue($)'},
            {key: 'ctr', title: 'CTR'},
            {key: 'cpc', title: 'CPC'},
            {key: 'ecpm', title: 'ECPM'},
        ];
        let data: any[] = [];
        let dataList = this.state.tableAdapter.pagedList.data;
        let totalGaDailyActive = 0;
        let totalViewsCount = 0;
        let totalClickCount = 0;
        let totalEstimatedIncome = 0;
        let totalCtr = 0;
        let totalCpc = 0;
        let totalEcpm = 0;

        dataList.forEach((advertData: AdvertData) => {
            let item = {
                datetime: advertData.datetime,
                channelName: this.state.channelNameMap.get(advertData.channelCode),
                gaDailyActive: advertData.gaDailyActive,
                viewsCount: advertData.viewsCount,
                clickCount: advertData.clickCount,
                estimatedIncome: advertData.estimatedIncome,
                ctr: advertData.ctr,
                cpc: advertData.cpc,
                ecpm: advertData.ecpm,
            };
            data.push(item);
            totalGaDailyActive += advertData.gaDailyActive;
            totalViewsCount += advertData.viewsCount;
            totalClickCount += advertData.clickCount;
            totalEstimatedIncome += advertData.estimatedIncome;
            totalCtr += advertData.ctr;
            totalCpc += advertData.cpc;
            totalEcpm += advertData.ecpm;
        })
        let endItem = {
            datetime: "Total",
            channelName: "",
            gaDailyActive: totalGaDailyActive,
            viewsCount: totalViewsCount,
            clickCount: totalClickCount,
            estimatedIncome: totalEstimatedIncome,
            ctr: totalCtr,
            cpc: totalCpc,
            ecpm: totalEcpm,
        };
        data.push(endItem);
        let date = new Date();
        let cellStyle: any[] = [{wpx: 80}, {wpx: 60}, {wpx: 100}, {wpx: 60}, {wpx: 60}, {wpx: 60}, {wpx: 60}, {wpx: 60}, {wpx: 60}, {wpx: 60}]
        exportExcel(headers, data, cellStyle, "advert_data_summary_report_" + date.getTime() + ".xlsx");
    }

    onExportChannelExcel() {
        let headers: any[] = [
            {key: 'datetime', title: 'Date'},
            {key: 'gaDailyActive', title: 'GA dau'},
            {key: 'viewsCount', title: 'Impressions'},
            {key: 'clickCount', title: 'Clicks'},
            {key: 'channelEstimatedIncome', title: 'Ad revenue($)'},
            {key: 'ctr', title: 'CTR'},
            {key: 'channelCpc', title: 'CPC'},
            {key: 'channelEcpm', title: 'ECPM'},
        ];
        let data: any[] = [];
        let dataList = this.state.tableAdapter.pagedList.data;
        let totalGaDailyActive = 0;
        let totalViewsCount = 0;
        let totalClickCount = 0;
        let totalCtr = 0;
        let totalChannelCpc = 0;
        let totalChannelEcpm = 0;
        let totalChannelEstimatedIncome = 0;
        dataList.forEach((advertData: AdvertData) => {
            let item = {
                datetime: advertData.datetime,
                gaDailyActive: advertData.gaDailyActive,
                viewsCount: advertData.viewsCount,
                clickCount: advertData.clickCount,
                channelEstimatedIncome: advertData.channelEstimatedIncome,
                ctr: advertData.ctr,
                channelCpc: advertData.channelCpc,
                channelEcpm: advertData.channelEcpm,
            };
            data.push(item);
            totalGaDailyActive += advertData.gaDailyActive;
            totalViewsCount += advertData.viewsCount;
            totalClickCount += advertData.clickCount;
            totalChannelEstimatedIncome += advertData.channelEstimatedIncome;
            totalCtr += advertData.ctr;
            totalChannelCpc += advertData.channelCpc;
            totalChannelEcpm += advertData.channelEcpm;
        })
        let endItem = {
            datetime: "Total",
            gaDailyActive: totalGaDailyActive,
            viewsCount: totalViewsCount,
            clickCount: totalClickCount,
            channelEstimatedIncome: totalChannelEstimatedIncome,
            ctr: totalCtr,
            channelCpc: totalChannelCpc,
            channelEcpm: totalChannelEcpm,
        };
        data.push(endItem);
        let date = new Date();
        let cellStyle: any[] = [{wpx: 80}, {wpx: 60}, {wpx: 60}, {wpx: 60}, {wpx: 60}, {wpx: 60}, {wpx: 60}, {wpx: 60}]
        exportExcel(headers, data, cellStyle, "advert_data_summary_report_" + date.getTime() + ".xlsx");
    }

    onFinishUpload() {
        FtSnackbars.showText("Import success");
        this.state.tableAdapter.changePageNo(0);
        AdminApiService.getLastImportDatetime((datetime: string) => {
            this.setState({lastImportDatetime: datetime});
        });
    }

    getFilterPanel(): ReactNode {
        return <FtRow cellHorizontalSpace={16} style={{margin: "0px 16px"}}>
            {AppClientInfo.clientInfo.loginUser.role !== "CHANNEL_ADMIN" &&
            <Autocomplete options={this.state.channelList}
                          getOptionLabel={(option) => option.name + " - " + option.code}
                          renderInput={(params) => <TextField {...params} label={"Channel code"}/>}
                          onChange={(event, value) => {
                              this.state.tableAdapter.filterColumns.set("channelCode", value?.code)
                              this.state.tableAdapter.filterColumns.set("channelNameAndCode", value?.name + " - " + value?.code)
                              this.forceUpdate();
                          }}
                          inputValue={this.state.tableAdapter.getFilterColumnValue("channelNameAndCode")}
                          style={{width: 200}}/>}
            <TextField id="startDate"
                       label={"Start date"}
                       type="date"
                       style={{width: 150}}
                       value={this.state.tableAdapter.getFilterColumnValue("startDate")}
                       InputLabelProps={{shrink: true,}}
                       onChange={event => {
                           this.state.tableAdapter.filterColumns.set("startDate", event.target.value);
                           this.forceUpdate();
                       }}
            />
            <TextField id="endDate"
                       label={"End date"}
                       type="date"
                       style={{width: 150}}
                       value={this.state.tableAdapter.getFilterColumnValue("endDate")}
                       InputLabelProps={{shrink: true,}}
                       onChange={event => {
                           this.state.tableAdapter.filterColumns.set("endDate", event.target.value);
                           this.forceUpdate();
                       }}
            />
        </FtRow>
    }

}
