import * as React from "react";
import {ReactNode} from "react";
import {RouteComponentProps} from "react-router";
import JtDataTable, {JtDataTableAdapter, JtDataTableColumnProps} from "../core/JtDataTable";
import FtToast from "../core/FtToast";
import JtState from "../core/JtState";
import {IconButton, TextField} from "@material-ui/core";
import AdminApiService from "../service/AdminApiService";
import JtPagedList from "../core/JtPagedList";
import FtRow from "../core/FtRow";
import {ArrowBack} from "@material-ui/icons";
import DailyOperationData from "../model/DailyOperationData";
import FtTextField from "../core/FtTextField";
import AppClientInfo from "../common/AppClientInfo";
import {Autocomplete} from "@material-ui/lab";
import ContentProvider from "../model/ContentProvider";
import Channel from "../model/Channel";
import FtFileUploadComp from "../core/FtFileUploadComp";
import FtSnackbars from "../core/FtSnackbars";

interface DailyOperationDataPageProp extends RouteComponentProps {

}

export class DailyOperationDataPageState {
    tableAdapter: TableAdapter = new TableAdapter();
    columnProps: Array<JtDataTableColumnProps> = [];
    contentProviderList: Array<ContentProvider> = [];
    channelList: Array<Channel> = [];
}

class TableAdapter extends JtDataTableAdapter<DailyOperationData> {

    loadData(): void {
        FtToast.showToastLoading();
        AdminApiService.getDailyOperationDataPagedList(this.keyword, this.filterColumns, this.sortColumn ? this.sortColumn.propertyName : null,
            this.sortDirection, this.pagedList.pageNo, this.pagedList.pageSize,
            (result: JtPagedList<DailyOperationData>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }

    resetSearch() {
        let channelId = this.filterColumns.get("channelId");
        let contentProviderId = this.filterColumns.get("contentProviderId");
        super.resetSearch();
        this.filterColumns.set("channelId", channelId);
        this.filterColumns.set("contentProviderId", contentProviderId);
    }
}

export default class DailyOperationDataPage extends React.Component<DailyOperationDataPageProp, DailyOperationDataPageState> {
    constructor(props: any) {
        super(props);
        const columnProps: Array<JtDataTableColumnProps> = [];
        const column0: JtDataTableColumnProps = new JtDataTableColumnProps("productCode", "产品编号", "center");
        columnProps.push(column0);
        const column2: JtDataTableColumnProps = new JtDataTableColumnProps("contentProviderCode", "CP编号", "center");
        columnProps.push(column2);
        const column3: JtDataTableColumnProps = new JtDataTableColumnProps("channelCode", "渠道编号", "center");
        columnProps.push(column3);
        const column4: JtDataTableColumnProps = new JtDataTableColumnProps("datetimeText", "日期", "center");
        columnProps.push(column4);
        const column5: JtDataTableColumnProps = new JtDataTableColumnProps("mediaExposureAmount", "媒体曝光量", "center");
        columnProps.push(column5);
        const column6: JtDataTableColumnProps = new JtDataTableColumnProps("mediaHitsAmount", "媒体点击量", "center");
        columnProps.push(column6);
        const column7: JtDataTableColumnProps = new JtDataTableColumnProps("mediaHits", "媒体点击率", "center");
        column7.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            return columnValue + "%";
        };
        columnProps.push(column7);
        const column8: JtDataTableColumnProps = new JtDataTableColumnProps("importedUsersCount", "导入用户数", "center");
        columnProps.push(column8);
        const column9: JtDataTableColumnProps = new JtDataTableColumnProps("productsViewsCount", "产品浏览量", "center");
        columnProps.push(column9);
        const column10: JtDataTableColumnProps = new JtDataTableColumnProps("advertExposureAmount", "广告展示量", "center");
        columnProps.push(column10);
        const column11: JtDataTableColumnProps = new JtDataTableColumnProps("advertHitsAmount", "广告点击量", "center");
        columnProps.push(column11);
        const column12: JtDataTableColumnProps = new JtDataTableColumnProps("estimatedIncome", "预估收入", "center");
        columnProps.push(column12);
        const column13: JtDataTableColumnProps = new JtDataTableColumnProps("advertHits", "广告点击率", "center");
        column13.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            return columnValue + "%";
        };
        columnProps.push(column13);
        const column14: JtDataTableColumnProps = new JtDataTableColumnProps("cpc", "CPC", "center");
        columnProps.push(column14);
        const column15: JtDataTableColumnProps = new JtDataTableColumnProps("ecpm1", "ECPM1", "center");
        columnProps.push(column15);
        const column16: JtDataTableColumnProps = new JtDataTableColumnProps("ecpm2", "ECPM2", "center");
        columnProps.push(column16);

        let tableAdapter: TableAdapter;
        const cacheState: DailyOperationDataPageState = JtState.pop("DailyOperationDataPageState");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new TableAdapter();
            if (AppClientInfo.clientInfo.loginUser.role === "CHANNEL_ADMIN") {
                tableAdapter.filterColumns.set("channelCode", AppClientInfo.clientInfo.loginUser.objectCode);
            } else if (AppClientInfo.clientInfo.loginUser.role === "CONTENT_PROVIDER_ADMIN") {
                tableAdapter.filterColumns.set("contentProviderCode", AppClientInfo.clientInfo.loginUser.objectCode);
            }
        }
        tableAdapter.onUpdateData = ((pagedList: JtPagedList<any>) => {
            this.forceUpdate();
        });
        this.state = {
            tableAdapter: tableAdapter,
            columnProps: columnProps,
            contentProviderList: [],
            channelList: [],
        };
        this.getFilterPanel = this.getFilterPanel.bind(this);
        this.leftNode = this.leftNode.bind(this);
        this.loadContentProvider = this.loadContentProvider.bind(this);
        this.loadChannel = this.loadChannel.bind(this);
        this.onFinishUpload = this.onFinishUpload.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
        this.loadContentProvider();
        this.loadChannel();
    }

    loadChannel(): void {
        AdminApiService.getChannelPagedList("", new Map<string, any>(), 0, 99999, (result: JtPagedList<Channel>) => {
            FtToast.closeToast();
            let data = result.data;
            this.setState({channelList: data});
        });
    }

    loadContentProvider(): void {
        AdminApiService.getContentProviderPagedList("", new Map<string, any>(), null,
            null, 0, 99999,
            (result: JtPagedList<ContentProvider>) => {
                let data = result.data;
                this.setState({contentProviderList: data});
            });
    }

    onFinishUpload() {
        FtSnackbars.showText("导入成功");
        this.state.tableAdapter.changePageNo(0);
    }

    render() {
        return <JtDataTable title={"每日运营数据统计"} columnProps={this.state.columnProps}
                            filterPanel={this.getFilterPanel()}
                            leftNode={this.leftNode()}
                            adapter={this.state.tableAdapter}/>
    }

    leftNode(): ReactNode {
        return <FtRow cellHorizontalSpace={16}>
            <IconButton onClick={() => {
                this.props.history.goBack()
            }}><ArrowBack/></IconButton>
            {AppClientInfo.clientInfo.loginUser.role === "ADMIN" &&
            <FtFileUploadComp fileType={".xls,.xlsx"}
                              uploadServiceFunction={AdminApiService.saveDailyOperationData}
                              onFinish={this.onFinishUpload}/>}
            {AppClientInfo.clientInfo.loginUser.role === "ADMIN" &&
            <a href={"excel/daily_operation_data_template.xlsx"} target={"_blank"}
               style={{textDecoration: "underline"}}>下载模板</a>}
        </FtRow>;
    }

    getFilterPanel(): ReactNode {
        return <FtRow cellHorizontalSpace={16} style={{margin: "0px 16px"}}>
            <FtTextField label={"产品编号"} style={{width: 200}} onChange={(event) => {
                let value = event.target.value;
                this.state.tableAdapter.filterColumns.set("productCode", value)
                this.forceUpdate();
            }} value={this.state.tableAdapter.getFilterColumnValue("productCode")}/>
            {AppClientInfo.clientInfo.loginUser.role !== "CONTENT_PROVIDER_ADMIN" &&
            <Autocomplete options={this.state.contentProviderList}
                          getOptionLabel={(option) => option.code}
                          renderInput={(params) => <TextField {...params} label={"CP编号"} value={""}/>}
                          onChange={(event, value) => {
                              this.state.tableAdapter.filterColumns.set("contentProviderCode", value?.code)
                              this.forceUpdate();
                          }}
                          inputValue={this.state.tableAdapter.getFilterColumnValue("contentProviderCode")}
                          style={{width: 200}}/>}
            {AppClientInfo.clientInfo.loginUser.role !== "CHANNEL_ADMIN" &&
            <Autocomplete options={this.state.channelList}
                          getOptionLabel={(option) => option.code}
                          renderInput={(params) => <TextField {...params} label={"渠道编号"}/>}
                          onChange={(event, value) => {
                              this.state.tableAdapter.filterColumns.set("channelCode", value?.code)
                              this.forceUpdate();
                          }}
                          inputValue={this.state.tableAdapter.getFilterColumnValue("channelCode")}
                          style={{width: 200}}/>}
            <TextField id="startDate"
                       label={"开始日期"}
                       type="date"
                       style={{width: 150}}
                       value={this.state.tableAdapter.getFilterColumnValue("startDate")}
                       InputLabelProps={{shrink: true,}}
                       onChange={event => {
                           this.state.tableAdapter.filterColumns.set("startDate", event.target.value);
                           this.forceUpdate();
                       }}
            />
            <TextField id="endDate"
                       label={"结束时间"}
                       type="date"
                       style={{width: 150}}
                       value={this.state.tableAdapter.getFilterColumnValue("endDate")}
                       InputLabelProps={{shrink: true,}}
                       onChange={event => {
                           this.state.tableAdapter.filterColumns.set("endDate", event.target.value);
                           this.forceUpdate();
                       }}
            />
        </FtRow>;
    }
}
