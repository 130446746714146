import WatchableData from "../core/WatchableData";
import React from "react";


export class ClientDataState{
    userId: string = "";
    constructor() {

    }
}

export default class ClientData extends WatchableData {
    static data:ClientDataState=new ClientDataState();

    static getData(): ClientDataState {
        return ClientData.data;
    }

    static saveUserIdToLocalStorage(userId: string) {
        window.localStorage.setItem("ClientData.userId", userId);
    }

    static getUserIdByLocalStorage(): string {
        const str: string | null = window.localStorage.getItem("ClientData.userId");
        if (str == null){
            return "";
        }
        return str;
    }

    static clearLocalStorageUserId(){
        window.localStorage.removeItem("ClientData.userId");
    }

    static isLogin():boolean{
        const userId = ClientData.getUserIdByLocalStorage();
        if (userId.trim().length > 0) {
            return true;
        }
        return false;
    }
}