import * as React from "react";
import {RouteComponentProps} from "react-router";
import {grey} from "@material-ui/core/colors";
import {AppBar, Card, CardActionArea, CardContent, CardMedia, Typography} from "@material-ui/core";
import FtRow from "../core/FtRow";
import BingNews, {BingNewsImage, BingNewsProvider, Thumbnail} from "../model/BingNews";
import FtToast from "../core/FtToast";
import NewService from "../service/NewService";

interface BingNewsListPageProp extends RouteComponentProps{

}

class BingNewsListPageState {
    newsList: Array<BingNews> = [];
    selectedNews: BingNews = new BingNews();
}

let mainWidth = document.documentElement.clientWidth;

export default class BingNewsListPage extends React.Component<BingNewsListPageProp, BingNewsListPageState> {

    constructor(props: any) {
        super(props);

        this.state = {newsList: [], selectedNews: new BingNews()};

        this.getHeaderComp = this.getHeaderComp.bind(this);
        this.loadNews = this.loadNews.bind(this);
        this.onClickDetail = this.onClickDetail.bind(this);
    }

    componentDidMount(): void {
        this.loadNews();
    }

    render() {
        return <div>
            {this.getHeaderComp()}
            <div style={{paddingTop: 48}}/>
            <FtRow cellWidthS={"1"} cellVerticalSpace={0} cellHorizontalSpace={0} style={{textAlign: "center", paddingBottom: 8}}>
                {this.state.newsList && this.state.newsList.length > 0 && this.state.newsList.map((news: BingNews)=>{
                    if (news.image) {
                        return <Card style={{margin: "8px 8px 0px 8px"}}>
                            <CardActionArea onClick={()=>{this.onClickDetail(news)}}>
                                <CardMedia image={news.image.thumbnail.contentUrl} style={{height: 200}}/>
                                <CardContent style={{textAlign: "left", padding: "8px 8px 0px 8px"}}>
                                    <Typography gutterBottom variant="h6" style={{fontWeight: "bold"}}>
                                        {news.name}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    }
                })}
            </FtRow>
        </div>
    }

    onClickDetail(news: BingNews) {
        BingNews.selectedNews = news;
        this.props.history.push("/news/newsDetail")
    }

    loadNews() {
        // let newsList: Array<BingNews> = [];
        // let news: BingNews = new BingNews();
        // news.name = "岸田首相、ファイザーＣＥＯと電話会談…３回目接種へワクチン供給の前倒し要請か";
        // news.url = "https://www.msn.com/ja-jp/news/national/%E5%B2%B8%E7%94%B0%E9%A6%96%E7%9B%B8-%E3%83%95%E3%82%A1%E3%82%A4%E3%82%B6%E3%83%BC%EF%BD%83%EF%BD%85%EF%BD%8F%E3%81%A8%E9%9B%BB%E8%A9%B1%E4%BC%9A%E8%AB%87-%EF%BC%93%E5%9B%9E%E7%9B%AE%E6%8E%A5%E7%A8%AE%E3%81%B8%E3%83%AF%E3%82%AF%E3%83%81%E3%83%B3%E4%BE%9B%E7%B5%A6%E3%81%AE%E5%89%8D%E5%80%92%E3%81%97%E8%A6%81%E8%AB%8B%E3%81%8B/ar-AARTFK3";
        // news.description = "岸田首相は１７日朝、米製薬大手ファイザーのアルバート・ブーラ最高経営責任者（ＣＥＯ）と電話会談を行った。新型コロナウイルスワクチンの３回目接種を巡り、供給の前倒しを要請したとみられる。";
        // news.datePublished = "2021-12-17T00:42:55.0000000Z";
        // let thumbnail: Thumbnail = new Thumbnail();
        // thumbnail.contentUrl = "https://www.bing.com/th?id=OVFT.dtIEX_lqMV_SPs1Y_mdhli&pid=News";
        // let image: BingNewsImage = new BingNewsImage();
        // image.thumbnail = thumbnail;
        // news.image = image;
        // let provider: Array<BingNewsProvider> = [];
        // let p: BingNewsProvider = new BingNewsProvider;
        // p.name = "読売新聞";
        // let thumbnail2: Thumbnail = new Thumbnail();
        // thumbnail2.contentUrl = "https://www.bing.com/th?id=ODF.Z9So-j-_-9-hk26YdKUbDw&pid=news";
        // let image2: BingNewsImage = new BingNewsImage();
        // image2.thumbnail = thumbnail2;
        // p.image = image2;
        // provider.push(p);
        // news.provider = provider;
        // news.baseUrl = "https://www.bing.com/news/search?q=top+stories&form=TNSA02";
        // newsList.push(news);
        // newsList.push(news);
        // this.setState({newsList: newsList});
        FtToast.showToastLoading();
        NewService.getBingNewsList((bingNewsList: Array<BingNews>)=>{
            FtToast.closeToast();
            this.setState({newsList: bingNewsList});
        });
    }

    getHeaderComp() {
        return <AppBar position="fixed" style={{width: mainWidth, maxWidth: 500, left: "auto", right: "auto"}}>
            <div style={{height: 48, margin: "auto"}}>
                <Typography variant="h5" style={{lineHeight: "48px", color: "black", fontWeight: "bold"}}>DAILY NEWS</Typography>
            </div>
        </AppBar>;
    }
}