import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import {RouteComponentProps} from "react-router";
import JtDataTable, {JtDataTableAdapter, JtDataTableColumnProps} from "../core/JtDataTable";
import FtToast from "../core/FtToast";
import JtState from "../core/JtState";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    IconButton,
    MenuItem,
    TextField,
    Tooltip,
    Typography
} from "@material-ui/core";
import AdminApiService from "../service/AdminApiService";
import JtPagedList from "../core/JtPagedList";
import FtTextField from "../core/FtTextField";
import FtRow from "../core/FtRow";
import ContentProvider from "../model/ContentProvider";
import FtUtil from "../core/FtUtil";
import Product, {EProductStatus} from "../model/Product";
import {ArrowBack, FileCopyOutlined} from "@material-ui/icons";
import {Autocomplete} from "@material-ui/lab";
import FtCheckboxGroup from "../core/FtCheckboxGroup";
import Channel from "../model/Channel";
import Link from '@material-ui/core/Link';
import FtSnackbars from "../core/FtSnackbars";
import copy from 'copy-to-clipboard';

interface ProductPageProp extends RouteComponentProps {

}

export class ProductPageState {
    tableAdapter: TableAdapter = new TableAdapter();
    columnProps: Array<JtDataTableColumnProps> = [];
    openDialog: boolean = false;
    openTitle: string = "";
    update: Product = new Product;
    contentProviderList: Array<ContentProvider> = [];
    channelList: Array<Channel> = [];
}

class TableAdapter extends JtDataTableAdapter<Product> {

    loadData(): void {
        FtToast.showToastLoading();
        AdminApiService.getProductPagedList(this.keyword, this.filterColumns, this.sortColumn ? this.sortColumn.propertyName : null,
            this.sortDirection, this.pagedList.pageNo, this.pagedList.pageSize,
            (result: JtPagedList<Product>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}

export default class ProductPage extends React.Component<ProductPageProp, ProductPageState> {
    constructor(props: any) {
        super(props);
        const columnProps: Array<JtDataTableColumnProps> = [];
        const column0: JtDataTableColumnProps = new JtDataTableColumnProps("code", "编号");
        column0.sortable = true;
        column0.renderHeader = () => {
            return <Typography style={{paddingLeft: 12}}>编号</Typography>
        }
        column0.renderCell = (rowData: object) => {
            const product = rowData as Product;
            return <Link>
                <Button color={"primary"} size={"small"}
                        onClick={() => {
                            this.setState({
                                openDialog: true,
                                openTitle: "更新产品",
                                update: FtUtil.mergeObject(new Product(), product)
                            });
                        }}>
                    {product.code}
                </Button>
            </Link>
        };
        columnProps.push(column0);
        const column3: JtDataTableColumnProps = new JtDataTableColumnProps("name", "产品名称");
        columnProps.push(column3);
        const column1: JtDataTableColumnProps = new JtDataTableColumnProps("contentProvider.name", "CP名称");
        columnProps.push(column1);
        const column2: JtDataTableColumnProps = new JtDataTableColumnProps("channel.name", "渠道名称");
        columnProps.push(column2);
        const advertType: JtDataTableColumnProps = new JtDataTableColumnProps("advertType", "广告类型");
        advertType.sortable = true;
        columnProps.push(advertType);
        const column5: JtDataTableColumnProps = new JtDataTableColumnProps("productType", "网站内容");
        columnProps.push(column5);
        const column6: JtDataTableColumnProps = new JtDataTableColumnProps("productStatusText", "状态");
        column6.sortable = true;
        columnProps.push(column6);
        const productNote: JtDataTableColumnProps = new JtDataTableColumnProps("productNote", "备注");
        columnProps.push(productNote);
        const note: JtDataTableColumnProps = new JtDataTableColumnProps("note", "渠道备注");
        columnProps.push(note);
        const productUrl: JtDataTableColumnProps = new JtDataTableColumnProps("productUrl", "产品链接", "left", 500);
        productUrl.renderCell = (rowData: object) => {
            const product = rowData as Product;
            return <div style={{display: "flex", width: 500}}>
                <Typography color={"primary"} style={{maxWidth: 475, wordBreak: "break-word"}}>
                    {product.productUrl}
                </Typography>
                <Tooltip title={"复制URL"}>
                    <IconButton size={"small"} style={{marginLeft: 8}}
                                onClick={() => {
                                    copy(product.productUrl);
                                    FtSnackbars.showText("复制成功", 10000, "info");
                                }}>
                        <FileCopyOutlined style={{width: 15, height: "auto"}}/>
                    </IconButton>
                </Tooltip>
            </div>
        };
        columnProps.push(productUrl);
        const dataLastUpdateTime: JtDataTableColumnProps = new JtDataTableColumnProps("dataLastUpdateTime", "数据更新时间");
        columnProps.push(dataLastUpdateTime);

        let tableAdapter: TableAdapter;
        const cacheState: ProductPageState = JtState.pop("ProductPageState");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new TableAdapter();
        }
        tableAdapter.onUpdateData = ((pagedList: JtPagedList<any>) => {
            this.forceUpdate();
        });
        this.state = {
            tableAdapter: tableAdapter,
            columnProps: columnProps,
            openDialog: false,
            openTitle: "",
            update: new Product(),
            contentProviderList: [],
            channelList: [],
        };
        this.getFilterPanel = this.getFilterPanel.bind(this);
        this.getUpdateDialog = this.getUpdateDialog.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.leftNode = this.leftNode.bind(this);
        this.onChange = this.onChange.bind(this);
        this.loadContentProvider = this.loadContentProvider.bind(this);
        this.loadChannel = this.loadChannel.bind(this);
        this.onChangeNumber = this.onChangeNumber.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
        this.loadContentProvider();
        this.loadChannel();
    }

    loadChannel(): void {
        AdminApiService.getChannelPagedList("", new Map<string, any>(), 0, 99999, (result: JtPagedList<Channel>) => {
            FtToast.closeToast();
            let data = result.data;
            this.setState({channelList: data});
        });
    }

    loadContentProvider(): void {
        AdminApiService.getContentProviderPagedList("", new Map<string, any>(), null,
            null, 0, 99999,
            (result: JtPagedList<ContentProvider>) => {
                let data = result.data;
                this.setState({contentProviderList: data});
            });
    }

    render() {
        return <div>
            <JtDataTable title={"产品列表"} columnProps={this.state.columnProps}
                         cellPadding={4}
                         filterPanel={this.getFilterPanel()}
                         leftNode={this.leftNode()}
                         adapter={this.state.tableAdapter}/>
            {this.getUpdateDialog()}
        </div>
    }

    leftNode(): ReactNode {
        return <FtRow cellHorizontalSpace={16}>
            <IconButton onClick={() => {
                this.props.history.goBack()
            }}><ArrowBack/></IconButton>
            <Button variant={"contained"} color={"primary"} onClick={() => {
                this.setState({openDialog: true, openTitle: "添加产品", update: new Product()});
            }}>添加产品</Button>
        </FtRow>;
    }

    getFilterPanel(): ReactNode {
        return <FtRow cellHorizontalSpace={16} style={{margin: "0px 16px"}}>
            <FtTextField label={"关键字"} style={{width: 150}} onChange={(event) => {
                let value = event.target.value;
                this.state.tableAdapter.keyword = value ? value : "";
                this.forceUpdate();
            }} value={this.state.tableAdapter.keyword}/>
            <Autocomplete options={this.state.contentProviderList}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => <TextField {...params} label={"CP名称"}/>}
                          onChange={(event, value) => {
                              this.state.tableAdapter.filterColumns.set("contentProviderId", value?.id)
                              this.state.tableAdapter.filterColumns.set("contentProviderAutocomplete", value?.name)
                              this.forceUpdate();
                          }}
                          inputValue={this.state.tableAdapter.getFilterColumnValue("contentProviderAutocomplete")}
                          style={{width: 200}}/>
            <Autocomplete options={this.state.channelList}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => <TextField {...params} label={"渠道名称"}/>}
                          onChange={(event, value) => {
                              this.state.tableAdapter.filterColumns.set("channelCode", value?.code)
                              this.state.tableAdapter.filterColumns.set("channelAutocomplete", value?.name)
                              this.forceUpdate();
                          }}
                          inputValue={this.state.tableAdapter.getFilterColumnValue("channelAutocomplete")}
                          style={{width: 200}}/>
            <FtTextField label={"广告类型"} style={{width: 150}} onChange={(event) => {
                this.state.tableAdapter.filterColumns.set("advertType", event.target.value)
                this.forceUpdate();
            }} value={this.state.tableAdapter.getFilterColumnValue("advertType")}/>
            <FormControl>
                <FormLabel>产品状态</FormLabel>
                <FtCheckboxGroup row={true}
                                 values={[EProductStatus.UNDER_DECLARATION, EProductStatus.ONLINE, EProductStatus.OFFLINE]}
                                 labels={["申报中", "上线", "下线"]}
                                 checkedValues={this.state.tableAdapter.filterColumns.get("productStatus")}
                                 onCheck={(checkedValue) => {
                                     this.state.tableAdapter.filterColumns.set("productStatus", checkedValue);
                                     this.forceUpdate();
                                 }}/>
            </FormControl>
        </FtRow>;
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
        FtUtil.setProperty(this.state.update, event.target.id, event.target.value);
        this.forceUpdate();
    }

    onChangeNumber(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
        // const regexpFloat = new RegExp("^[0-9]+[\.]{0,1}([0-9]{0,2})?$");
        const regexpInteger = new RegExp("^[-+]?[0-9]+$");
        let value = event.target.value;
        if (value.length > 0) {
            if (regexpInteger.test(value)) {
                FtUtil.setProperty(this.state.update, event.target.id, value);
                this.forceUpdate();
            }
        } else {
            FtUtil.setProperty(this.state.update, event.target.id, "");
            this.forceUpdate();
        }
    }

    getUpdateDialog(): ReactNode {
        return <Dialog open={this.state.openDialog} PaperProps={{style: {width: 600}}}>
            <DialogTitle>
                <Typography>{this.state.openTitle}</Typography>
            </DialogTitle>
            <DialogContent style={{padding: "6px 24px"}} dividers>
                <FtRow cellWidthS={"1,1"} cellHorizontalAlign={"center"}>
                    <FtTextField id={"code"} type={"text"} label="产品编号"
                                 disabled={this.state.update.id !== null && this.state.update.id > 0}
                                 style={{width: "100%"}} onChange={this.onChange}
                                 value={this.state.update.code}/>
                    <FtTextField id={"name"} type={"text"} label="产品名称"
                                 style={{width: "100%"}} onChange={this.onChange}
                                 value={this.state.update.name}/>
                    <FtTextField id={"advertType"} select label="广告类型"
                                 style={{width: "100%", textAlign: "left"}}
                                 onChange={(event) => {
                                     this.state.update.advertType = event.target.value;
                                     this.forceUpdate();
                                 }}
                                 value={this.state.update.advertType}>
                        <MenuItem key={"AdSense"} value={"AdSense"}>{"AdSense"}</MenuItem>
                        <MenuItem key={"ADX-AdSense"} value={"ADX-AdSense"}>{"ADX-AdSense"}</MenuItem>
                        <MenuItem key={"Admob"} value={"Admob"}>{"Admob"}</MenuItem>
                        <MenuItem key={"ADX-Admob"} value={"ADX-Admob"}>{"ADX-Admob"}</MenuItem>
                        <MenuItem key={"Taboola"} value={"Taboola"}>{"Taboola"}</MenuItem>
                        <MenuItem key={"Other"} value={"Other"}>{"其他"}</MenuItem>
                    </FtTextField>
                    <FtTextField id={"productType"} type={"text"} label="产品内容"
                                 style={{width: "100%"}} onChange={this.onChange}
                                 value={this.state.update.productType}/>
                    <FtTextField id={"channelCode"} select label="所属渠道"
                                 style={{width: "100%", textAlign: "left"}}
                                 onChange={(event) => {
                                     this.state.update.channelCode = event.target.value;
                                     this.forceUpdate();
                                 }}
                                 value={this.state.update.channelCode}>
                        {this.state.channelList.map((channel: Channel) => {
                            return <MenuItem key={channel.code} value={channel.code}>{channel.name}</MenuItem>;
                        })}
                    </FtTextField>
                    <FtTextField id={"contentProviderId"} select label="所属CP"
                                 style={{width: "100%", textAlign: "left"}}
                                 onChange={(event) => {
                                     this.state.update.contentProviderId = +event.target.value;
                                     this.forceUpdate();
                                 }}
                                 value={this.state.update.contentProviderId}>
                        {this.state.contentProviderList.map((contentProvider: ContentProvider) => {
                            return <MenuItem key={contentProvider.id}
                                             value={contentProvider.id}>{contentProvider.name}</MenuItem>;
                        })}
                    </FtTextField>
                    <FtTextField id={"productStatus"} select type={"text"} label="产品状态"
                                 style={{width: "100%", textAlign: "left"}}
                                 onChange={(event) => {
                                     this.state.update.productStatus = event.target.value as EProductStatus;
                                     this.forceUpdate();
                                 }}
                                 value={this.state.update.productStatus}>
                        <MenuItem key={EProductStatus.UNDER_DECLARATION}
                                  value={EProductStatus.UNDER_DECLARATION}>申报中</MenuItem>
                        <MenuItem key={EProductStatus.ONLINE} value={EProductStatus.ONLINE}>上线</MenuItem>
                        <MenuItem key={EProductStatus.OFFLINE} value={EProductStatus.OFFLINE}>下线</MenuItem>
                    </FtTextField>
                    <FtTextField id={"productNote"} type={"text"} label="备注"
                                 style={{width: "100%"}} onChange={this.onChange}
                                 value={this.state.update.productNote}/>
                </FtRow>
                <FtRow cellWidthS={"1"} cellHorizontalAlign={"center"}>
                    <FtTextField id={"productUrl"} type={"text"} label="产品链接"
                                 style={{width: "100%"}} onChange={this.onChange}
                                 value={this.state.update.productUrl}/>
                </FtRow>
            </DialogContent>
            <DialogActions style={{display: "flex", justifyContent: "space-between", padding: "16px 24px"}}>
                <Button onClick={() => {
                    this.setState({openDialog: false})
                }} variant={"contained"} color={"secondary"}>关闭</Button>
                <Button onClick={this.onClickSave} variant={"contained"} color={"primary"}>保存</Button>
            </DialogActions>
        </Dialog>;
    }

    onClickSave() {
        FtToast.showToastLoading();
        AdminApiService.saveProduct(this.state.update, (product: Product) => {
            FtToast.closeToast();
            this.setState({openDialog: false});
            this.state.tableAdapter.loadData();
        });
    }
}
