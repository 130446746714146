import * as React from "react";
import {Button} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";

export class JtMessageAlertProps {

}

export class JtMessageAlertState {
    open: boolean = false;
    content?: string | React.ReactNode;
    buttonLabel?: string = "确定";
    onButtonClick?: () => void;
}

export default class JtMessageAlert extends React.Component<JtMessageAlertProps, JtMessageAlertState> {
    static jtMessageAlert: JtMessageAlert;

    constructor(props: any) {
        super(props);
        this.state = {open: false};
        this.close = this.close.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
    }

    static showText(text: string | React.ReactNode, buttonLabel?: string, onButtonClick?:()=>void): void {
        if (JtMessageAlert.jtMessageAlert) {
            JtMessageAlert.jtMessageAlert.show(text, buttonLabel, onButtonClick);
        }
    }

    close() {
        this.setState({open: false});
    }

    show(text: string | React.ReactNode, buttonLabel?: string, onButtonClick?:()=>void): void {
        this.setState({
            open: true,
            content: text,
            buttonLabel: buttonLabel,
            onButtonClick: onButtonClick,
        });
    }

    onButtonClick() {
        this.close();
        if (this.state.onButtonClick){
            this.state.onButtonClick();
        }
    }

    render() {
        return <Dialog open={this.state.open} PaperProps={{style: {minWidth: 200}}}>
            <DialogContent style={{margin:8}}>
                {this.state.content}
            </DialogContent>
            <DialogActions style={{display:"flex",justifyContent:"space-between",margin:8, alignSelf: "center"}}>
                <Button variant={"contained"} color={"primary"} onClick={this.onButtonClick}>{this.state.buttonLabel}</Button>
            </DialogActions>
        </Dialog>;
    }
};