import React from 'react';
import './App.css';
import {HashRouter, Route} from "react-router-dom";
import {MuiThemeProvider} from "@material-ui/core";
import {theme} from "./Theme";
import JtMessageDialog from "./core/JtMessageDialog";
import FtSnackbars from "./core/FtSnackbars";
import JtMessageAlert from "./core/JtMessageAlert";
import FtToast from "./core/FtToast";
import MainPage from "./main/MainPage";
import BingNewsMainPage from "./main/BingNewsMainPage";

function App() {
    if (window.location.href.indexOf("/news") > -1) {
        return <HashRouter>
            <MuiThemeProvider theme={theme}>
                {/*<Route path="/news" component={BingNewsListPage} exact={false}/>*/}
                {/*<Route path="/news/newsDetail" component={BingNewsDetailPage} exact={false}/>*/}
                <Route path="/news" component={BingNewsMainPage} exact={false}/>
            </MuiThemeProvider>
        </HashRouter>;
    } else {
        return <HashRouter>
            <MuiThemeProvider theme={theme}>
                <JtMessageDialog ref={(element: any) => {
                    JtMessageDialog.jtMessageDialog = element;
                }} />
                <FtSnackbars ref={(element: any) => {
                    FtSnackbars.jtSnackbars = element;
                }} />
                <JtMessageAlert ref={(element: any) => {
                    JtMessageAlert.jtMessageAlert = element;
                }} />
                <FtToast ref={(element: any) => {
                    FtToast.jtToast = element;
                }} />
                <Route path="/" component={MainPage} exact={false}/>
            </MuiThemeProvider>
        </HashRouter>;
    }
}

export default App;
