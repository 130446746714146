
export default class GameOperationData {
    id: number = 0;
    code: string = "";
    contentProviderCode: string = "";
    appId: string = "";
    appName: string = "";
    ariesCode: string = "";
    money: number = 0;
    country: string = "";
    coinType: string = "";
    calculateType: string = "";
    finishedDate: string = "";
    createDatetime: string = "";
    dateTotalMoney: number = 0;
    dateTotalCount: number = 0;
}
