import HttpService from "./HttpService";
import AjaxHelper from "../core/AjaxHelper";
import BingNews from "../model/BingNews";

export default class NewService extends HttpService {

    static getNewsPaged(successFunction: (data: any) => void): void {
        let params = {"limit": 10, "page":1, "newsTypeId": "","language": ""};
        AjaxHelper.postJson("https://www.migame.vip/gamefront/newsList/findByPage", params,
            (data: any, textStatus: string, response: any) => {
                successFunction(data);
            },  HttpService.handleError, {"Content-Type": "application/json;charset=UTF-8"});
    }

    static getBingNewsList(successFunction: (bingNewsList: Array<BingNews>) => void): void {
        HttpService.postFormData("/news/api/getBingNewsList", {}, successFunction, HttpService.handleError);
    }
}