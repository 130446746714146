import Cookies from 'universal-cookie';
import {History, LocationState} from "history";
import _ from "lodash";

declare const device: any;

export default class FtUtil {
    /**
     * @method 深复制一个json对象
     * @param source 需要深复制的对象
     * @return 返回一个新的json对象
     * @author vincent 2018-11-29
     * @version 0.0.0
     * @example
     * @log 1. vincent,2018-11-29, func、date、reg 和 err 类型不能正常拷贝
     */
    static deepCopyJson(source: any): Object {
        const newObject: any = {};
        for (const key of Object.keys(source)) {
            newObject[key] = typeof source[key] === 'object' ? FtUtil.deepCopyJson(source[key]) : source[key];
        }
        return newObject;
    }

    static getProperty(source: any, propertyName: string): any {
        if (source == undefined || source == null)
            return source;
        let currentPropertyName: string = "";
        let subPropertyName: string = "";
        let result: any;
        const index = propertyName.indexOf(".");
        if (index > 0) {
            subPropertyName = propertyName.substring(index + 1);
            currentPropertyName = propertyName.substr(0, index);
        } else {
            currentPropertyName = propertyName;
        }
        for (const key of Object.keys(source)) {
            if (key == currentPropertyName)
                result = source[key];
        }
        if (subPropertyName == "")
            return result;
        else
            return FtUtil.getProperty(result, subPropertyName);
    }

    static setProperty(source: any, propertyName: string, value: any): Object {
        //改用lodash之后，即使属性是?undefined，也可以赋值成功
        const data: any = [];
        data[propertyName] = value;
        return _.assign(source, data);
    }

    static cloneObject<T>(value: T): T {
        return _.clone(value);
    }

    /**
     * 合并两个对象
     * @param object1
     * @param object2
     */
    static mergeObject(object1: any, object2?: any, ...objectN: any[]): any {
        // return $.extend(true, {}, object1, object2, ...objectN);
        // 改loadash
        return _.merge({}, object1, object2, ...objectN);
    }

    static toCurrencyText(src: number | undefined): string {
        if (src != null && src.toFixed)
            return "$ " + src.toFixed(2);
        else
            return "";
    }

    static toCurrency(src: number | undefined): string {
        if (src != null)
            return src.toFixed(2);
        else
            return "0";
    }

    /**
     * 把数组转换成字符串
     * @param array
     */
    static arrayToString(array: Array<any>) {
        let str: string = "";
        if (array != null) {
            array.forEach((value: any) => {
                if (value != null)
                    str += value + ",";
            });
        }
        if (str.length > 1)
            str.substring(0, str.length - 1);
        return str;
    }

    static stringToArray(source: string, delimiter: string = ",") {
        let list: Array<string> = [];
        if (source != null) {
            list = source.split(delimiter);
        }
        if (list != null && list.length > 0 && list[list.length - 1] == "")
            list.pop();
        return list;
    }

    static getFileNameNoExt(name: string) {
        const index = name.lastIndexOf('.');
        if (index == -1)
            return name;
        else
            return name.substring(0, index);
    }

    static getFileExtName(name: string) {
        const index = name.lastIndexOf('.');
        if (index == -1)
            return "";
        else
            return name.substring(index + 1, name.length);
    }

    static getParameterByName(name: string, url: string): string {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return "";
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    static setCookie(name: string, value: string, domain: string = this.getDomain(), path: string = "/", maxAgeInSecond?: number): void {
        const cookies = new Cookies();
        let options: any = {};
        if (domain)
            options["domain"] = domain;
        if (path)
            options["path"] = path;
        if (maxAgeInSecond)
            options["maxAge"] = maxAgeInSecond;
        cookies.set(name, value, options);
    }

    static getCookie(name: string, domain: string = this.getDomain(), path: string = "/"): string {
        const cookies = new Cookies();
        let options: any = {};
        if (domain)
            options["domain"] = domain;
        if (path)
            options["path"] = path;
        return cookies.get(name, options);
    }

    static deleteCookie(name: string, domain: string = this.getDomain(), path: string = "/"): void {
        const cookies = new Cookies();
        let options: any = {};
        if (domain)
            options["domain"] = domain;
        if (path)
            options["path"] = path;
        cookies.remove(name, options);
    }

    static getDomain(): string {
        return window.location.hostname;
    }

    static getCurrentDatetime(): string {
        const now = new Date();
        return now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate() + " " + now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds();
    }

    static goBack(history: History<LocationState>, homeUrl?: string, step?: number) {
        if (history.length <= 1 || (step != null && step * -1 >= history.length))
            history.push(homeUrl ? homeUrl : "/");
        else if (step != null && step < -1)
            history.go(step);
        else
            history.goBack();
    }

    static toPercentText(num: number) {
        const roundingPrecision = 4;
        const roundedNum = _.round(num * 100, roundingPrecision);
        return roundedNum + '%';
    }

    static objectToMap(object: any): Map<string, string> {
        let data: Map<string, string> = new Map<string, string>();
        if (object != null) {
            let strings = Object.keys(object);
            strings.forEach((key: string) => {
                data.set(key, object[key]);
            })
        }
        return data;
    }
}
