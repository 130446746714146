import {Theme} from "@material-ui/core";
import {createTheme} from '@material-ui/core/styles';
import {grey} from "@material-ui/core/colors";

export let theme: Theme;
theme = createTheme({
    palette: {
        primary: {
            light: "#2196f3",
            main: "#2196f3",
            dark: "#2196f3",
            contrastText: "#fff"
        },
        secondary: {
            light: "#ff2800",
            main: "#ff2800",
            dark: "#ff2800",
            contrastText: "#fff"
        }
    },
    typography: {
        // Tell Material-UI what's the font-size on the html element is.
        fontSize: 12,
        h6:{
            fontSize: 14,
        }
    },
    overrides: {
        MuiBottomNavigationAction: {
            root: {
                color: "#666666",
                "&$selected": {
                    color: "red",
                    fontWeight: 700
                }
            }
        },
        MuiAppBar: {
            root: {
                backgroundColor: "#ffffff"
            },
            colorPrimary: {
                backgroundColor: "#ffffff",
                color: "#ff2800"
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: 48
            }
        },
        MuiDialog: {
            paperFullScreen: {
                backgroundColor: grey[200]
            },
            paperScrollBody: {
                "-webkit-overflow-scrolling": "touch"
            },
            paperScrollPaper: {
                "-webkit-overflow-scrolling": "touch"
            }
        },
        MuiCard: {
            root: {
                marginTop: 4,
                marginBottom: 4,
                marginLeft: 0,
                marginRight: 0,
                padding: 4,
            }
        },
        MuiFab: {
            root: {
                width: 48,
                height: 48
            }
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
            }
        }
    }
});