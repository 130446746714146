import * as React from "react";
import {CSSProperties, HTMLAttributes} from "react";
import FtUtil from "./FtUtil";

export interface FtDivFlexProp extends HTMLAttributes<HTMLDivElement>{

}

export default class FtDivFlex extends React.Component<FtDivFlexProp, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        let styles:CSSProperties={};
        styles.display="flex";
        styles.marginTop=2;
        styles.marginBottom=2;
        styles.justifyContent="flex-start";
        styles.alignItems="center";
        styles.flexDirection="row";
        if(styles.flexDirection=="row") {
            styles.flexWrap="wrap";
        }
        if(this.props.style!=null) {
            styles=FtUtil.mergeObject(styles,this.props.style);
        }
        return <div className={"JtDivFlex"} style={styles} onClick={this.props.onClick}>
            {this.props.children}
        </div>;
    }
}