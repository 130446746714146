import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import {RouteComponentProps} from "react-router";
import JtDataTable, {JtDataTableAdapter, JtDataTableColumnProps} from "../core/JtDataTable";
import FtToast from "../core/FtToast";
import JtState from "../core/JtState";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    Typography
} from "@material-ui/core";
import AdminApiService from "../service/AdminApiService";
import JtPagedList from "../core/JtPagedList";
import FtTextField from "../core/FtTextField";
import FtRow from "../core/FtRow";
import ContentProvider from "../model/ContentProvider";
import FtUtil from "../core/FtUtil";
import {ArrowBack} from "@material-ui/icons";
import Link from "@material-ui/core/Link";
import Channel from "../model/Channel";
import User from "../model/User";
import FtSnackbars from "../core/FtSnackbars";

interface ContentProviderPageProp extends RouteComponentProps {

}

export class ContentProviderPageState {
    tableAdapter: TableAdapter = new TableAdapter();
    columnProps: Array<JtDataTableColumnProps> = [];
    openDialog: boolean = false;
    openTitle: string = "";
    updateCP: ContentProvider = new ContentProvider;
}

class TableAdapter extends JtDataTableAdapter<ContentProvider> {

    loadData(): void {
        FtToast.showToastLoading();
        AdminApiService.getContentProviderPagedList(this.keyword, this.filterColumns, this.sortColumn ? this.sortColumn.propertyName : null,
            this.sortDirection, this.pagedList.pageNo, this.pagedList.pageSize,
            (result: JtPagedList<ContentProvider>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}

export default class ContentProviderPage extends React.Component<ContentProviderPageProp, ContentProviderPageState> {
    constructor(props: any) {
        super(props);
        const columnProps: Array<JtDataTableColumnProps> = [];
        const column0: JtDataTableColumnProps = new JtDataTableColumnProps("code", "编号");
        column0.renderHeader = () => {
            return <Typography style={{paddingLeft: 12}}>编号</Typography>
        }
        column0.renderCell = (rowData: object) => {
            const contentProvider = rowData as ContentProvider;
            return <Link>
                <Button color={"primary"} size={"small"}
                        onClick={() => {
                            const newContentProvider = FtUtil.mergeObject(new ContentProvider(), contentProvider) as ContentProvider;
                            if (newContentProvider.user == null) {
                                newContentProvider.user = new User();
                            }
                            this.setState({openDialog: true, openTitle: "更新CP", updateCP: newContentProvider});
                        }}>
                    {contentProvider.code}
                </Button>
            </Link>
        };
        columnProps.push(column0);
        const column2: JtDataTableColumnProps = new JtDataTableColumnProps("name", "CP名称");
        columnProps.push(column2);
        const column3: JtDataTableColumnProps = new JtDataTableColumnProps("divideProportionText", "分成比例");
        columnProps.push(column3);
        const column5: JtDataTableColumnProps = new JtDataTableColumnProps("url", "后台地址");
        columnProps.push(column5);
        const column1: JtDataTableColumnProps = new JtDataTableColumnProps("user.contacts", "联系人", "center");
        columnProps.push(column1);
        const column6: JtDataTableColumnProps = new JtDataTableColumnProps("user.contactMobile", "联系电话", "center");
        columnProps.push(column6);
        const column7: JtDataTableColumnProps = new JtDataTableColumnProps("user.loginName", "登录账号", "center");
        columnProps.push(column7);
        const column8: JtDataTableColumnProps = new JtDataTableColumnProps("user.password", "登录密码", "center");
        columnProps.push(column8);
        const column11: JtDataTableColumnProps = new JtDataTableColumnProps("user.lastLoginDatetimeText", "最后登录时间", "center");
        columnProps.push(column11);
        const column12: JtDataTableColumnProps = new JtDataTableColumnProps("user.loginTimes", "登录次数", "center");
        columnProps.push(column12);
        const column9: JtDataTableColumnProps = new JtDataTableColumnProps("updateUserName", "最后操作人");
        columnProps.push(column9);

        let tableAdapter: TableAdapter;
        const cacheState: ContentProviderPageState = JtState.pop("ContentProviderPageState");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new TableAdapter();
        }
        tableAdapter.onUpdateData = ((pagedList: JtPagedList<any>) => {
            this.forceUpdate();
        });
        this.state = {
            tableAdapter: tableAdapter,
            columnProps: columnProps,
            openDialog: false,
            openTitle: "",
            updateCP: new ContentProvider(),
        };
        this.getFilterPanel = this.getFilterPanel.bind(this);
        this.getUpdateDialog = this.getUpdateDialog.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.leftNode = this.leftNode.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeUser = this.onChangeUser.bind(this);
        this.onChangeNumber = this.onChangeNumber.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    render() {
        return <div>
            <JtDataTable title={"CP列表"} columnProps={this.state.columnProps}
                         filterPanel={this.getFilterPanel()}
                         cellPadding={4}
                         leftNode={this.leftNode()}
                         adapter={this.state.tableAdapter}/>

            {this.getUpdateDialog()}
        </div>
    }

    leftNode(): ReactNode {
        return <FtRow cellHorizontalSpace={16}>
            <IconButton onClick={() => {
                this.props.history.goBack()
            }}><ArrowBack/></IconButton>
            <Button variant={"contained"} color={"primary"} onClick={() => {
                this.setState({openDialog: true, openTitle: "添加CP", updateCP: new ContentProvider()});
            }}>添加CP</Button>
        </FtRow>;
    }

    getFilterPanel(): ReactNode {
        return <FtRow cellHorizontalSpace={16} style={{margin: "0px 16px"}}>
            <FtTextField label={"关键字"} style={{width: 150}} onChange={(event) => {
                let value = event.target.value;
                this.state.tableAdapter.keyword = value ? value : "";
                this.forceUpdate();
            }} value={this.state.tableAdapter.keyword}/>
        </FtRow>;
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
        FtUtil.setProperty(this.state.updateCP, event.target.id, event.target.value);
        this.forceUpdate();
    }

    onChangeUser(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
        FtUtil.setProperty(this.state.updateCP.user, event.target.id, event.target.value);
        this.forceUpdate();
    }

    onChangeNumber(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
        const regexpInteger = new RegExp("^[-+]?[0-9]+$");
        let value = event.target.value;
        if (value.length > 0) {
            if (regexpInteger.test(value)) {
                FtUtil.setProperty(this.state.updateCP, event.target.id, value);
                this.forceUpdate();
            }
        } else {
            FtUtil.setProperty(this.state.updateCP, event.target.id, "");
            this.forceUpdate();
        }
    }

    getUpdateDialog(): ReactNode {
        return <Dialog open={this.state.openDialog} PaperProps={{style: {width: 900}}}>
            <DialogTitle>
                <Typography>{this.state.openTitle}</Typography>
            </DialogTitle>
            <DialogContent style={{padding: "6px 24px"}} dividers>
                <FtRow cellWidthS={"1,1"} cellHorizontalAlign={"center"}>
                    <FtTextField id={"code"} type={"text"} label="CP编号"
                                 disabled={this.state.updateCP.id !== null && this.state.updateCP.id > 0}
                                 style={{width: "100%"}} onChange={this.onChange}
                                 value={this.state.updateCP.code}/>
                    <FtTextField id={"name"} type={"text"} label="CP名称"
                                 style={{width: "100%"}} onChange={this.onChange}
                                 value={this.state.updateCP.name}/>
                    <FtTextField id={"divideProportion"} type={"text"} label="分成比例"
                                 style={{width: "100%"}} onChange={this.onChangeNumber}
                                 endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                 value={this.state.updateCP.divideProportion}/>
                    <FtTextField id={"returnProportion"} type={"text"} label="返点比例"
                                 style={{width: "100%"}} onChange={this.onChangeNumber}
                                 endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                 value={this.state.updateCP.returnProportion}/>
                </FtRow>
                <FtRow cellWidthS={"1"} cellHorizontalAlign={"center"}>
                    <FtTextField id={"url"} type={"text"} label="后台地址"
                                 style={{width: "100%"}} onChange={this.onChange}
                                 value={this.state.updateCP.url}/>
                </FtRow>
                <FtRow cellWidthS={"1,1"} cellHorizontalAlign={"center"}>
                    <FtTextField id={"contacts"} label="联系人"
                                 style={{width: "100%"}} onChange={this.onChangeUser}
                                 value={this.state.updateCP.user.contacts}/>
                    <FtTextField id={"contactMobile"} label="联系电话"
                                 style={{width: "100%"}} onChange={this.onChangeUser}
                                 value={this.state.updateCP.user.contactMobile}/>
                    <FtTextField id={"loginName"} label="登录账号"
                                 style={{width: "100%"}} onChange={this.onChangeUser}
                                 value={this.state.updateCP.user.loginName}/>
                    <FtTextField id={"password"} label="登录密码"
                                 style={{width: "100%"}} onChange={this.onChangeUser}
                                 value={this.state.updateCP.user.password}/>
                </FtRow>
            </DialogContent>
            <DialogActions style={{display: "flex", justifyContent: "space-between", padding: "16px 24px"}}>
                <Button onClick={() => {
                    this.setState({openDialog: false})
                }} variant={"contained"} color={"secondary"}>关闭</Button>
                <Button onClick={this.onClickSave} variant={"contained"} color={"primary"}>保存</Button>
            </DialogActions>
        </Dialog>;
    }

    onClickSave() {
        let user = this.state.updateCP.user;
        let message = User.verifyNewUser(user);
        if (message.length > 0) {
            FtSnackbars.showText(message, 60000, "error");
            return;
        }
        FtToast.showToastLoading();
        AdminApiService.saveContentProvider(this.state.updateCP, (contentProvider: ContentProvider) => {
            FtToast.closeToast();
            this.setState({openDialog: false});
            this.state.tableAdapter.loadData();
        });
    }
}
