import * as React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@material-ui/core";
import FtTextField from "../core/FtTextField";
import FtToast from "../core/FtToast";
import FtSnackbars from "../core/FtSnackbars";
import AdminApiService from "../service/AdminApiService";
import ApiResponse from "../core/ApiResponse";

interface ChangePasswordDialogProp {

}

export class ChangePasswordDialogState {
    openDialog: boolean = false;
    oldPassword: string = "";
    newPassword1: string = "";
    newPassword2: string = "";
}

export default class ChangePasswordDialog extends React.Component<ChangePasswordDialogProp, ChangePasswordDialogState> {
    constructor(props: any) {
        super(props);
        this.state = {
            openDialog: false,
            oldPassword: "",
            newPassword1: "",
            newPassword2: "",
        };
        this.onClickSave = this.onClickSave.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount(): void {

    }

    show() {
        this.setState({openDialog: true, oldPassword: "", newPassword1: "", newPassword2: "",})
    }

    render() {
        return <Dialog open={this.state.openDialog} PaperProps={{style: {maxWidth: 300}}}>
            <DialogTitle>
                <Typography>修改密码</Typography>
            </DialogTitle>
            <DialogContent style={{padding: "6px 24px"}} dividers>
                <FtTextField id={"oldPassword"} label="原始密码" type={"password"}
                             style={{width: "100%"}}
                             onChange={(event) => {
                                 this.setState({oldPassword: event.target.value});
                             }}
                             value={this.state.oldPassword}/>
                <FtTextField id={"newPassword1"} label="新密码" type={"password"}
                             style={{width: "100%"}}
                             onChange={(event) => {
                                 this.setState({newPassword1: event.target.value});
                             }}
                             value={this.state.newPassword1}/>
                <FtTextField id={"newPassword2"} label="再次输入新密码" type={"password"}
                             style={{width: "100%"}}
                             onChange={(event) => {
                                 this.setState({newPassword2: event.target.value});
                             }}
                             value={this.state.newPassword2}/>
            </DialogContent>
            <DialogActions style={{display: "flex", justifyContent: "space-between", padding: "16px 24px"}}>
                <Button onClick={this.onClose}
                        variant={"contained"} color={"secondary"}>关闭</Button>
                <Button onClick={this.onClickSave} variant={"contained"} color={"primary"}>保存</Button>
            </DialogActions>
        </Dialog>
    }

    onClickSave() {
        if (this.state.oldPassword == null || this.state.oldPassword.trim().length == 0) {
            FtSnackbars.showText("原始密码不能为空", 10000, "error");
            return;
        }
        if (this.state.newPassword1 == null || this.state.newPassword1.trim().length == 0) {
            FtSnackbars.showText("新密码不能为空", 10000, "error");
            return;
        }
        if (this.state.newPassword2 == null || this.state.newPassword2.trim().length == 0) {
            FtSnackbars.showText("请再次输入新密码", 10000, "error");
            return;
        }
        if (this.state.newPassword1 !== this.state.newPassword2) {
            FtSnackbars.showText("新密码两次输入不一致", 10000, "error");
            return;
        }
        FtToast.showToastLoading();
        AdminApiService.changePassword(this.state.oldPassword, this.state.newPassword1, (data: string) => {
            FtToast.closeToast();
            this.setState({openDialog: false});
            FtSnackbars.showText(data, 10000, "info");
        }, (response: ApiResponse) => {
            FtToast.closeToast();
            if (response.message) {
                FtSnackbars.showText(response.message, 10000, "error");
            }
        });
    }

    onClose() {
        this.setState({openDialog: false})
    }
}
