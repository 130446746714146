import * as React from "react";
import {ReactNode} from "react";
import {RouteComponentProps} from "react-router";
import JtDataTable, {JtDataTableAdapter, JtDataTableColumnProps} from "../core/JtDataTable";
import FtToast from "../core/FtToast";
import JtState from "../core/JtState";
import {Button} from "@material-ui/core";
import AdminApiService from "../service/AdminApiService";
import JtPagedList from "../core/JtPagedList";
import FtRow from "../core/FtRow";
import FtSnackbars from "../core/FtSnackbars";
import AppClientInfo from "../common/AppClientInfo";
import GameOperationData from "../model/GameOperationData";
import ClientData from "../common/ClientData";
import FtFileUploadComp from "../core/FtFileUploadComp";

interface GameOperationDataPageProp extends RouteComponentProps {

}

export class GameOperationDataPageState {
    tableAdapter: TableAdapter = new TableAdapter();
    columnProps: Array<JtDataTableColumnProps> = [];
}

class TableAdapter extends JtDataTableAdapter<GameOperationData> {

    loadData(): void {
        FtToast.showToastLoading();
        AdminApiService.getGameOperationDataPagedList(this.keyword, this.filterColumns, this.sortColumn ? this.sortColumn.propertyName : null,
            this.sortDirection, this.pagedList.pageNo, this.pagedList.pageSize,
            (result: JtPagedList<GameOperationData>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }

    resetSearch() {
        let contentProviderId = this.filterColumns.get("contentProviderId");
        super.resetSearch();
        this.filterColumns.set("contentProviderId", contentProviderId);
    }
}

export default class GameOperationDataPage extends React.Component<GameOperationDataPageProp, GameOperationDataPageState> {
    constructor(props: any) {
        super(props);
        const columnProps: Array<JtDataTableColumnProps> = [];
        const column0: JtDataTableColumnProps = new JtDataTableColumnProps("code", "商户编码", "center");
        columnProps.push(column0);
        const column2: JtDataTableColumnProps = new JtDataTableColumnProps("contentProviderCode", "CP编号", "center");
        columnProps.push(column2);
        const column3: JtDataTableColumnProps = new JtDataTableColumnProps("appId", "应用编码", "center");
        columnProps.push(column3);
        const column4: JtDataTableColumnProps = new JtDataTableColumnProps("appName", "应用名称", "center");
        columnProps.push(column4);
        const column5: JtDataTableColumnProps = new JtDataTableColumnProps("ariesCode", "Aries单号", "center");
        columnProps.push(column5);
        const column6: JtDataTableColumnProps = new JtDataTableColumnProps("money", "金额", "center");
        columnProps.push(column6);
        const column7: JtDataTableColumnProps = new JtDataTableColumnProps("country", "国家", "center");
        columnProps.push(column7);
        const column8: JtDataTableColumnProps = new JtDataTableColumnProps("coinType", "币种", "center");
        columnProps.push(column8);
        const column9: JtDataTableColumnProps = new JtDataTableColumnProps("calculateType", "计费方式", "center");
        columnProps.push(column9);
        const column10: JtDataTableColumnProps = new JtDataTableColumnProps("finishedDate", "完成时间", "center");
        columnProps.push(column10);

        let tableAdapter: TableAdapter;
        const cacheState: GameOperationDataPageState = JtState.pop("GameOperationDataPageState");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new TableAdapter();
            if (AppClientInfo.clientInfo.loginUser.role === "CONTENT_PROVIDER_ADMIN") {
                tableAdapter.filterColumns.set("contentProviderCode", AppClientInfo.clientInfo.loginUser.objectCode);
            }
        }
        tableAdapter.onUpdateData = ((pagedList: JtPagedList<any>) => {
            this.forceUpdate();
        });
        this.state = {
            tableAdapter: tableAdapter,
            columnProps: columnProps,
        };

        this.leftNode = this.leftNode.bind(this);
        this.onFinishUpload = this.onFinishUpload.bind(this);
        this.onUpdateUsdMoney = this.onUpdateUsdMoney.bind(this);
        this.onExportExcel = this.onExportExcel.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    render() {
        return <JtDataTable title={"游戏运营数据统计"} columnProps={this.state.columnProps}
                            leftNode={this.leftNode()}
                            adapter={this.state.tableAdapter}/>
    }

    onExportExcel() {
        FtToast.showToastLoading("正在导出...");
        fetch(AppClientInfo.clientInfo.apiServerUrl + "/admin/excel/exportExcel", {
            method: "get",
            headers: {userId: ClientData.getUserIdByLocalStorage()},
        }).then((response: Response) => {
            FtToast.closeToast();
            response.blob().then(blob => {
                let blobUrl = window.URL.createObjectURL(blob);
                var aElement = document.createElement('a');
                let filename = response.headers.get('filename') + "";//设置文件名称
                aElement.href = blobUrl;//设置a标签路径
                aElement.download = filename;
                aElement.click();
                window.URL.revokeObjectURL(blobUrl);
            });
        });
    }

    leftNode(): ReactNode {
        return <FtRow cellHorizontalSpace={16}>
            {/*<IconButton onClick={() => {*/}
            {/*    this.props.history.goBack()*/}
            {/*}}><ArrowBack/></IconButton>*/}
            {AppClientInfo.clientInfo.loginUser.role === "ADMIN" &&
            <FtFileUploadComp fileType={".xls,.xlsx"}
                              uploadServiceFunction={AdminApiService.saveGameOperationData}
                              onFinish={this.onFinishUpload}/>}
            <Button onClick={this.onExportExcel} variant={"contained"} style={{textTransform: "none"}}>导出Excel</Button>
            {AppClientInfo.clientInfo.loginUser.role === "ADMIN" &&
            <a href={"excel/game_operation_data_template.xlsx"} target={"_blank"}
               style={{textDecoration: "underline"}}>下载模板</a>}
            {AppClientInfo.clientInfo.loginUser.role === "ADMIN" &&
            <Button variant={"contained"} onClick={this.onUpdateUsdMoney} color={"primary"}>汇率换算</Button>}
        </FtRow>;
    }

    onUpdateUsdMoney() {
        FtToast.showToastLoading("正在换算", 60000);
        AdminApiService.calculationGameDataMoney((msg: string) => {
            FtToast.closeToast();
            FtSnackbars.showText(msg, 60000);
            this.state.tableAdapter.loadData();
        });
    }

    onFinishUpload() {
        FtSnackbars.showText("导入成功");
        this.state.tableAdapter.changePageNo(0);
    }
}
