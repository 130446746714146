import AjaxHelper from "../core/AjaxHelper";
import AppClientInfo from "../common/AppClientInfo";
import FtSnackbars from "../core/FtSnackbars";
import FtToast from "../core/FtToast";
import ClientData from "../common/ClientData";
import React from "react";


export default class HttpService {
    static getRequestHeaders() {
        const headers: any = [];
        headers.icsToken = AppClientInfo.clientInfo.icsToken;
        headers.content_type = "text/html;charset=UTF-8";
        headers.userId = ClientData.getUserIdByLocalStorage();
        return headers;
    }

    static get(uri: string, params: any, successFunction: (response: any) => void, failFunction?: (error: any) => void): void {
        AjaxHelper.get(AppClientInfo.clientInfo.apiServerUrl + uri, params, (data: any, textStatus: string, response: any) => {
            successFunction(data);
        }, failFunction, HttpService.getRequestHeaders());
    }

    static search(uri: string, searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null, pageNo: number, pageSize: number, successFunction: (response: any) => void, failFunction?: (error: any) => void): void {
        const params: any = {
            searchKeyword: searchKeyword,
            sortBy: sortPropertyName == null ? "" : sortPropertyName,
            sortDirection: sortDirection == null ? "asc" : sortDirection,
            pageNo: pageNo,
            pageSize: pageSize
        };
        filterColumns.forEach((value: any, key: string) => {
            params[key] = value;
        });
        HttpService.get(uri, params, successFunction, HttpService.handleError);
    }

    static postFormData(uri: string, params: any, successFunction: (response: any) => void, failFunction?: (error: any) => void): void {
        AjaxHelper.postFormData(AppClientInfo.clientInfo.apiServerUrl + uri, params, (data: any, textStatus: string, response: any) => {
            successFunction(data);
        }, failFunction, HttpService.getRequestHeaders());
    }

    static postJson(uri: string, jsonObject: any, successFunction: (response: any) => void, failFunction?: (error: any) => void): void {
        AjaxHelper.postJson(AppClientInfo.clientInfo.apiServerUrl + uri, jsonObject,
            (data: any, textStatus: string, response: any) => {
                successFunction(data);
            }, failFunction, HttpService.getRequestHeaders());
    }

    static handleError(apiResponse: any): void {
        FtToast.closeToast();
        if (apiResponse && apiResponse.message) {
            //如果本身是在确认对话框里进行操作导致的错误处理，无法再次使用messageDialog
            if (apiResponse.errorCode && apiResponse.errorCode == 101) {
                ClientData.clearLocalStorageUserId();
                window.location.reload();
            } else {
                FtSnackbars.showText("错误：" + apiResponse.message, 6000, "error");
            }
        }
    }

    static upload(uri: string, file: any, successFunction: (data: any) => void, progressFunction: (percent: number) => void, failFunction: (error: any) => void) {
        var formData = new FormData();
        formData.append("uploadFile", file);
        AjaxHelper.uploadFile(AppClientInfo.clientInfo.apiServerUrl + uri, formData, successFunction, (e: any) => {
            if (e.lengthComputable) {
                var max = e.total;
                var current = e.loaded;
                var Percentage = (current * 100) / max;
                progressFunction(Percentage);
            }
        }, failFunction, HttpService.getRequestHeaders());
    }
}
