import * as React from "react";
import {ReactNode} from "react";
import {RouteComponentProps} from "react-router";
import JtDataTable, {JtDataTableAdapter, JtDataTableColumnProps} from "../core/JtDataTable";
import FtToast from "../core/FtToast";
import JtState from "../core/JtState";
import AdminApiService from "../service/AdminApiService";
import JtPagedList from "../core/JtPagedList";
import FtRow from "../core/FtRow";
import GameOperationData from "../model/GameOperationData";
import {Button, MenuItem, TextField} from "@material-ui/core";
import FtUtil from "../core/FtUtil";
import FtTextField from "../core/FtTextField";
import AppClientInfo from "../common/AppClientInfo";
import {exportExcel} from "./ImportGameOperationDataComp";

interface GameOperationRatePageProp extends RouteComponentProps {

}

export class GameOperationRatePageState {
    tableAdapter: TableAdapter = new TableAdapter();
    columnProps: Array<JtDataTableColumnProps> = [];
}

class TableAdapter extends JtDataTableAdapter<GameOperationData> {

    loadData(): void {
        FtToast.showToastLoading();
        AdminApiService.getGameOperationDataSummary(this.filterColumns, this.sortColumn ? this.sortColumn.propertyName : null,
            this.sortDirection, this.pagedList.pageNo, this.pagedList.pageSize,
            (result: JtPagedList<GameOperationData>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }

    resetSearch() {
        let contentProviderId = this.filterColumns.get("contentProviderId");
        super.resetSearch();
        this.filterColumns.set("contentProviderId", contentProviderId);
    }
}

export default class GameOperationRatePage extends React.Component<GameOperationRatePageProp, GameOperationRatePageState> {
    constructor(props: any) {
        super(props);
        const columnProps: Array<JtDataTableColumnProps> = [];
        const column0: JtDataTableColumnProps = new JtDataTableColumnProps("finishedDate", "日期", "center");
        columnProps.push(column0);
        const column2: JtDataTableColumnProps = new JtDataTableColumnProps("dateTotalCount", "计费次数", "center");
        columnProps.push(column2);
        const column5: JtDataTableColumnProps = new JtDataTableColumnProps("dateTotalMoney", "预估收入（USD）", "center");
        column5.renderCell = (rowData: object) => {
            const data = rowData as GameOperationData;
            return FtUtil.toCurrency(data.dateTotalMoney);
        };
        columnProps.push(column5);
        let tableAdapter: TableAdapter;
        const cacheState: GameOperationRatePageState = JtState.pop("GameOperationRatePageState");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new TableAdapter();
            if (AppClientInfo.clientInfo.loginUser.role === "CONTENT_PROVIDER_ADMIN") {
                tableAdapter.filterColumns.set("contentProviderCode", AppClientInfo.clientInfo.loginUser.objectCode);
            }
        }
        tableAdapter.onUpdateData = ((pagedList: JtPagedList<any>) => {
            this.forceUpdate();
        });
        this.state = {
            tableAdapter: tableAdapter,
            columnProps: columnProps,
        };

        this.getFilterPanel = this.getFilterPanel.bind(this);
        this.leftNode = this.leftNode.bind(this);
        this.onExportExcel = this.onExportExcel.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    render() {
        return <JtDataTable title={""} columnProps={this.state.columnProps}
                            filterPanel={this.getFilterPanel()}
                            leftNode={this.leftNode()}
                            adapter={this.state.tableAdapter}/>
    }

    onExportExcel() {
        let headers: any[] = [
            {key: 'finishedDate', title: '日期'},
            {key: 'dateTotalCount', title: '计费次数'},
            {key: 'dateTotalMoney', title: '预估收入（USD）'},
        ];
        let data: any[] = [];
        let dataList = this.state.tableAdapter.pagedList.data;
        dataList.forEach((g: GameOperationData) => {
            let item = {
                finishedDate: g.finishedDate,
                dateTotalCount: g.dateTotalCount,
                dateTotalMoney: FtUtil.toCurrency(g.dateTotalMoney),
            };
            data.push(item);
        })
        let date = new Date();
        exportExcel(headers, data, date.getTime() + ".xlsx");
    }

    leftNode(): ReactNode {
        return <FtRow cellHorizontalSpace={16}>
            <Button onClick={this.onExportExcel} variant={"contained"} style={{textTransform: "none"}}>导出Excel</Button>
        </FtRow>;
    }

    getFilterPanel(): ReactNode {
        return <FtRow cellHorizontalSpace={16} style={{margin: "0px 16px"}}>
            <TextField id="startDate"
                       label={"开始日期"}
                       type="date"
                       style={{width: 150}}
                       value={this.state.tableAdapter.getFilterColumnValue("startDate")}
                       InputLabelProps={{shrink: true,}}
                       onChange={event => {
                           this.state.tableAdapter.filterColumns.set("startDate", event.target.value);
                           this.forceUpdate();
                       }}
            />
            <TextField id="endDate"
                       label={"结束时间"}
                       type="date"
                       style={{width: 150}}
                       value={this.state.tableAdapter.getFilterColumnValue("endDate")}
                       InputLabelProps={{shrink: true,}}
                       onChange={event => {
                           this.state.tableAdapter.filterColumns.set("endDate", event.target.value);
                           this.forceUpdate();
                       }}
            />
            {AppClientInfo.clientInfo.loginUser.role !== "CONTENT_PROVIDER_ADMIN" &&
            <TextField select id="contentProviderCode" label={"CP"} style={{width: 200}}
                       value={this.state.tableAdapter.getFilterColumnValue("contentProviderCode")}
                       onChange={event => {
                           this.state.tableAdapter.filterColumns.set("contentProviderCode", event.target.value);
                           this.forceUpdate();
                       }}>
                <MenuItem value={"2015"}>千御行</MenuItem>
                <MenuItem value={"2022"}>应美</MenuItem>
                <MenuItem value={"2023"}>信天游</MenuItem>
                <MenuItem value={"2024"}>漫乐游</MenuItem>
            </TextField>}
            <FtTextField label={"游戏名称"} style={{width: 200}} onChange={(event) => {
                let value = event.target.value;
                this.state.tableAdapter.filterColumns.set("appName", value)
                this.forceUpdate();
            }} value={this.state.tableAdapter.getFilterColumnValue("appName")}/>
        </FtRow>;
    }
}
