import * as React from "react";
import {RouteComponentProps} from "react-router";
import {AppBar, Button, Card, CardContent, CardMedia, Divider, IconButton, Typography} from "@material-ui/core";
import FtRow from "../core/FtRow";
import BingNews from "../model/BingNews";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {ChevronRight} from "@material-ui/icons";
import { Link } from "react-router-dom";

interface BingNewsDetailPageProp extends RouteComponentProps {

}

class BingNewsDetailPageState {

}

let mainWidth = document.documentElement.clientWidth;

export default class BingNewsDetailPage extends React.Component<BingNewsDetailPageProp, BingNewsDetailPageState> {

    constructor(props: any) {
        super(props);
        this.state = {};
        this.getHeaderComp = this.getHeaderComp.bind(this);
    }

    componentDidMount(): void {

        // this.setState({news: selectedNews});
    }

    render() {
        let news = BingNews.selectedNews;
        return <div>
            {this.getHeaderComp()}
            <div style={{paddingTop: 48}}/>
            {news && <FtRow cellWidthS={"1"} cellVerticalSpace={0} cellHorizontalSpace={0} style={{textAlign: "center"}}>
                <Card style={{margin: "8px 8px 0px 8px"}}>
                    <CardContent style={{textAlign: "left", padding: "8px 8px 0px 8px"}}>
                        <Typography gutterBottom variant="h6" style={{fontWeight: "bold"}}>
                            {news.name}
                        </Typography>
                        <Typography color={"textSecondary"} variant="body2" style={{fontWeight: "bold"}}>
                            {news.datePublished}
                        </Typography>
                        <Divider style={{marginTop: 8, marginBottom: 16}}/>
                        <Typography variant="body1">
                            {news.description}
                        </Typography>
                        {news.image&&<CardMedia image={news.image.thumbnail.contentUrl} style={{height: 200, marginTop: 16}}/>}
                        <FtRow justifyContent={"flex-end"}>
                            <a href={news.baseUrl} target={"_blank"}>
                                <Button color={"primary"} endIcon={<ChevronRight />}>Read More</Button>
                            </a>
                        </FtRow>
                    </CardContent>
                </Card>
            </FtRow>}
        </div>
    }

    getHeaderComp() {
        return <AppBar position="fixed" style={{width: mainWidth, maxWidth: 500, left: "auto", right: "auto"}}>
            <FtRow style={{height: 48, margin: "auto", width: "100%"}} cellHorizontalSpace={0} cellVerticalSpace={0} cellWidthS={"1,3,1"}>
                <IconButton onClick={()=>{this.props.history.goBack()}}><ArrowBackIcon/></IconButton>
                <Typography variant="h5" style={{lineHeight: "48px", color: "black", fontWeight: "bold", textAlign: "center"}}>NEWS DETAIL</Typography>
                <div/>
            </FtRow>
        </AppBar>;
    }
}