import * as React from "react";
import {ReactNode} from "react";
import {IconButton, Typography} from "@material-ui/core";
import {Route, RouteComponentProps} from "react-router";
import AppClientInfo from "../common/AppClientInfo";
import {grey} from "@material-ui/core/colors";
import FtAdminFrame from "../core/FtAdminFrame";
import FtRow from "../core/FtRow";
import {adminMenuData, channelMenuData, contentProviderMenuData, EMenuitem} from "./MainMenuData";
import ClientData from "../common/ClientData";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AdminApiService from "../service/AdminApiService";
import User from "../model/User";
import ContentProviderPage from "../contentProvider/ContentProviderPage";
import ProductPage from "../product/ProductPage";
import ProductControlPage from "../productControl/ProductControlPage";
import FtDivFlex from "../core/FtDivFlex";
import LoginPage from "../login/LoginPage";
import DailyOperationDataPage from "../dailyOperationData/DailyOperationDataPage";
import ChannelListPage from "../channel/ChannelListPage";
import BingNewsListPage from "../news/BingNewsListPage";
import BingNewsDetailPage from "../news/BingNewsDetailPage";

interface BingNewsMainPageProp extends RouteComponentProps {

}

class BingNewsMainPageState {

}

let mainWidth = document.documentElement.clientWidth;

export default class BingNewsMainPage extends React.Component<BingNewsMainPageProp, BingNewsMainPageState> {

    constructor(props: any) {
        super(props);

        this.state = {

        };
    }

    componentDidMount(): void {

    }

    render() {
        return <main style={{backgroundColor: grey[100], height: document.documentElement.clientHeight, width: mainWidth, maxWidth: 500, margin: "auto"}}>
            <Route path="/news/newsList" component={BingNewsListPage} exact={false}/>
            <Route path="/news/newsDetail" component={BingNewsDetailPage} exact={false}/>
        </main>
    }
}