import * as React from "react";
import {ReactNode} from "react";
import {RouteComponentProps} from "react-router";
import JtDataTable, {JtDataTableAdapter, JtDataTableColumnProps} from "../core/JtDataTable";
import FtToast from "../core/FtToast";
import JtState from "../core/JtState";
import AdminApiService from "../service/AdminApiService";
import JtPagedList from "../core/JtPagedList";
import FtTextField from "../core/FtTextField";
import FtRow from "../core/FtRow";
import AdvertData from "../model/AdvertData";
import AppClientInfo from "../common/AppClientInfo";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography
} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import Channel from "../model/Channel";
import {ArrowBack, Edit} from "@material-ui/icons";
import FtSnackbars from "../core/FtSnackbars";
import {exportExcel} from "../core/ExcelUtil";
import AdvertDataImportDialog from "./AdvertDataImportDialog";
import FtUtil from "../core/FtUtil";
import ContentProvider from "../model/ContentProvider";

interface AdverDailyDataReportListPageProp extends RouteComponentProps {

}

export class AdverDailyDataReportListPageState {
    tableAdapter: TableAdapter = new TableAdapter();
    columnProps: Array<JtDataTableColumnProps> = [];
    footerColumnProps: Array<string | ReactNode> = [];
    channelList: Array<Channel> = [];
    cpList: Array<ContentProvider> = [];
    channelNameMap: Map<string, string> = new Map<string, string>();
    cpNameMap: Map<string, string> = new Map<string, string>();
    productNameMap: Map<string, string> = new Map<string, string>();
    productNoteMap: Map<string, string> = new Map<string, string>();
    openDialog: boolean = false;
    selectProductCode?: string = "";
    selectProductNote?: string = "";
    lastImportDatetime?: string;
}

class TableAdapter extends JtDataTableAdapter<AdvertData> {
    channelCode: string | undefined = undefined;

    loadData(): void {
        FtToast.showToastLoading();
        if (this.channelCode) {
            this.filterColumns.set("channelCode", this.channelCode);
        }
        AdminApiService.getAdvertDataPagedList(this.keyword, this.filterColumns, this.pagedList.pageNo, this.pagedList.pageSize,
            (result: JtPagedList<AdvertData>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}

export default class AdverDailyDataReportListPage extends React.Component<AdverDailyDataReportListPageProp, AdverDailyDataReportListPageState> {
    importDialog!: AdvertDataImportDialog;

    constructor(props: any) {
        super(props);

        let tableAdapter: TableAdapter;
        const cacheState: AdverDailyDataReportListPageState = JtState.pop("AdverDailyDataReportListPageState");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new TableAdapter();
            tableAdapter.pagedList.pageSize = 100;
            if (AppClientInfo.clientInfo.loginUser.role === "CHANNEL_ADMIN") {
                tableAdapter.channelCode = AppClientInfo.clientInfo.loginUser.objectCode;
            }
        }
        tableAdapter.onUpdateData = ((pagedList: JtPagedList<any>) => {
            let gaDailyActive = 0;
            let viewsCount = 0;
            let clickCount = 0;
            let estimatedIncome = 0;
            let channelEstimatedIncome = 0;
            pagedList.data.forEach((data: AdvertData) => {
                gaDailyActive += data.gaDailyActive;
                viewsCount += data.viewsCount;
                clickCount += data.clickCount;
                estimatedIncome += data.estimatedIncome;
                channelEstimatedIncome += data.channelEstimatedIncome;
            });
            FtUtil
            let footerColumnProps: Array<string> = [];
            if (AppClientInfo.clientInfo.loginUser.role == "CHANNEL_ADMIN") {
                footerColumnProps = ["Total", "", gaDailyActive + "", "", "", viewsCount + "", "", clickCount + "", FtUtil.toCurrency(channelEstimatedIncome), "", "", ""];
            } else {
                footerColumnProps = ["Total", "", "", "", gaDailyActive + "", "", "", viewsCount + "", "", clickCount + "", FtUtil.toCurrency(estimatedIncome), "", "", ""];
            }
            this.setState({footerColumnProps: footerColumnProps}, this.forceUpdate);
        });
        this.state = {
            tableAdapter: tableAdapter,
            columnProps: [],
            channelList: [],
            cpList: [],
            footerColumnProps: [],
            channelNameMap: new Map<string, string>(),
            productNameMap: new Map<string, string>(),
            productNoteMap: new Map<string, string>(),
            cpNameMap: new Map<string, string>(),
            openDialog: false
        };
        this.getFilterPanel = this.getFilterPanel.bind(this);
        this.loadChannel = this.loadChannel.bind(this);
        this.loadCP = this.loadCP.bind(this);
        this.leftNode = this.leftNode.bind(this);
        this.onFinishUpload = this.onFinishUpload.bind(this);
        this.loadChannelMap = this.loadChannelMap.bind(this);
        this.loadCpNameMap = this.loadCpNameMap.bind(this);
        this.loadProductNameMap = this.loadProductNameMap.bind(this);
        this.loadProductNoteMap = this.loadProductNoteMap.bind(this);
        this.getChannelColumn = this.getChannelColumn.bind(this);
        this.getAdminColumn = this.getAdminColumn.bind(this);
        this.onSaveProductNote = this.onSaveProductNote.bind(this);
        this.loadLastImportDatetime = this.loadLastImportDatetime.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
        this.loadChannel();
        this.loadCP();
        this.loadChannelMap();
        this.loadCpNameMap();
        this.loadProductNameMap();
        this.loadProductNoteMap();
        this.loadLastImportDatetime();
        if (AppClientInfo.clientInfo.loginUser.role == "CHANNEL_ADMIN") {
            this.setState({columnProps: this.getChannelColumn()});
        } else {
            this.setState({columnProps: this.getAdminColumn()});
        }
    }

    // getStyled(rowData: object): CSSProperties {
    //     const advertData = rowData as AdvertData;
    //     const style: CSSProperties = {};
    //     if (advertData.coverage * 100 < 80) {
    //         style.color = "red";
    //     } else if (advertData.ctr * 100 > 10) {
    //         style.color = "red";
    //     } else if (advertData.clickCount == 0) {
    //         style.color = "red";
    //     } else if (advertData.estimatedIncome == 0) {
    //         style.color = "red";
    //     }
    //     return style;
    // }

    getAdminColumn() {
        const columnProps: Array<JtDataTableColumnProps> = [];
        const datetime: JtDataTableColumnProps = new JtDataTableColumnProps("datetime", "Date");
        // datetime.cellStyle = this.getStyled;
        columnProps.push(datetime);
        const channelName: JtDataTableColumnProps = new JtDataTableColumnProps("channelName", "Channel name");
        channelName.renderCell = (rowData: object) => {
            const advertData = rowData as AdvertData;
            return this.state.channelNameMap.get(advertData.channelCode);
        };
        // channelName.cellStyle = this.getStyled;
        columnProps.push(channelName);
        const cpName: JtDataTableColumnProps = new JtDataTableColumnProps("cpName", "CP name");
        cpName.renderCell = (rowData: object) => {
            const advertData = rowData as AdvertData;
            return this.state.cpNameMap.get(advertData.productCode);
        };
        columnProps.push(cpName);
        const productName: JtDataTableColumnProps = new JtDataTableColumnProps("productName", "Product name");
        productName.renderCell = (rowData: object) => {
            const advertData = rowData as AdvertData;
            return this.state.productNameMap.get(advertData.productCode);
        };
        // productName.cellStyle = this.getStyled;
        columnProps.push(productName);
        const gaDailyActive: JtDataTableColumnProps = new JtDataTableColumnProps("gaDailyActive", "GA dau");
        // gaDailyActive.cellStyle = this.getStyled;
        columnProps.push(gaDailyActive);
        const adRequests: JtDataTableColumnProps = new JtDataTableColumnProps("adRequests", "Requests");
        // adRequests.cellStyle = this.getStyled;
        columnProps.push(adRequests);
        const matchedRequests: JtDataTableColumnProps = new JtDataTableColumnProps("matchedRequests", "Matched requests");
        // matchedRequests.cellStyle = this.getStyled;
        columnProps.push(matchedRequests);
        const viewsCount: JtDataTableColumnProps = new JtDataTableColumnProps("viewsCount", "Impressions");
        // viewsCount.cellStyle = this.getStyled;
        columnProps.push(viewsCount);
        const coverage: JtDataTableColumnProps = new JtDataTableColumnProps("coverage", "Coverage");
        // coverage.cellStyle = this.getStyled;
        columnProps.push(coverage);
        const clickCount: JtDataTableColumnProps = new JtDataTableColumnProps("clickCount", "Clicks");
        // clickCount.cellStyle = this.getStyled;
        columnProps.push(clickCount);
        const estimatedIncome: JtDataTableColumnProps = new JtDataTableColumnProps("estimatedIncome", "Ad revenue($)");
        // estimatedIncome.cellStyle = this.getStyled;
        columnProps.push(estimatedIncome);
        const ctr: JtDataTableColumnProps = new JtDataTableColumnProps("ctr", "CTR");
        // ctr.cellStyle = this.getStyled;
        columnProps.push(ctr);
        const cpc: JtDataTableColumnProps = new JtDataTableColumnProps("cpc", "CPC");
        // cpc.cellStyle = this.getStyled;
        columnProps.push(cpc);
        const ecpm: JtDataTableColumnProps = new JtDataTableColumnProps("ecpm", "ECPM");
        // ecpm.cellStyle = this.getStyled;
        columnProps.push(ecpm);
        const notes: JtDataTableColumnProps = new JtDataTableColumnProps("note", "Product note");
        // ecpm.cellStyle = this.getStyled;
        notes.renderCell = (rowData: object) => {
            const advertData = rowData as AdvertData;
            return <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <span>{this.state.productNoteMap.get(advertData.productCode)}</span>
                <IconButton style={{padding: 4}} onClick={() => {
                    this.setState({
                        openDialog: true,
                        selectProductCode: advertData.productCode,
                        selectProductNote: this.state.productNoteMap.get(advertData.productCode)
                    })
                }}><Edit/></IconButton>
            </div>
        }
        columnProps.push(notes);
        return columnProps;
    }

    getChannelColumn() {
        const columnProps: Array<JtDataTableColumnProps> = [];
        const datetime: JtDataTableColumnProps = new JtDataTableColumnProps("datetime", "Date");
        // datetime.cellStyle = this.getStyled;
        columnProps.push(datetime);
        const productName: JtDataTableColumnProps = new JtDataTableColumnProps("productName", "Product name");
        productName.renderCell = (rowData: object,) => {
            const advertData = rowData as AdvertData;
            return this.state.productNameMap.get(advertData.productCode);
        };
        // productName.cellStyle = this.getStyled;
        columnProps.push(productName);
        const gaDailyActive: JtDataTableColumnProps = new JtDataTableColumnProps("gaDailyActive", "GA dau");
        // gaDailyActive.cellStyle = this.getStyled;
        columnProps.push(gaDailyActive);
        const adRequests: JtDataTableColumnProps = new JtDataTableColumnProps("adRequests", "Requests");
        // adRequests.cellStyle = this.getStyled;
        columnProps.push(adRequests);
        const matchedRequests: JtDataTableColumnProps = new JtDataTableColumnProps("matchedRequests", "Matched requests");
        // matchedRequests.cellStyle = this.getStyled;
        columnProps.push(matchedRequests);
        const viewsCount: JtDataTableColumnProps = new JtDataTableColumnProps("viewsCount", "Impressions");
        // viewsCount.cellStyle = this.getStyled;
        columnProps.push(viewsCount);
        const coverage: JtDataTableColumnProps = new JtDataTableColumnProps("coverage", "Coverage");
        // coverage.cellStyle = this.getStyled;
        columnProps.push(coverage);
        const clickCount: JtDataTableColumnProps = new JtDataTableColumnProps("clickCount", "Clicks");
        // clickCount.cellStyle = this.getStyled;
        columnProps.push(clickCount);
        const estimatedIncome: JtDataTableColumnProps = new JtDataTableColumnProps("channelEstimatedIncome", "Ad revenue($)");
        // estimatedIncome.cellStyle = this.getStyled;
        columnProps.push(estimatedIncome);
        const ctr: JtDataTableColumnProps = new JtDataTableColumnProps("ctr", "CTR");
        // ctr.cellStyle = this.getStyled;
        columnProps.push(ctr);
        const cpc: JtDataTableColumnProps = new JtDataTableColumnProps("channelCpc", "CPC");
        // cpc.cellStyle = this.getStyled;
        columnProps.push(cpc);
        const ecpm: JtDataTableColumnProps = new JtDataTableColumnProps("channelEcpm", "ECPM");
        // ecpm.cellStyle = this.getStyled;
        columnProps.push(ecpm);
        const notes: JtDataTableColumnProps = new JtDataTableColumnProps("note", "Product note");
        // ecpm.cellStyle = this.getStyled;
        notes.renderCell = (rowData: object) => {
            const advertData = rowData as AdvertData;
            return <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <span>{this.state.productNoteMap.get(advertData.productCode)}</span>
                <IconButton style={{padding: 4}} onClick={() => {
                    this.setState({
                        openDialog: true,
                        selectProductCode: advertData.productCode,
                        selectProductNote: this.state.productNoteMap.get(advertData.productCode),
                    })
                }}><Edit/></IconButton>
            </div>
        };
        columnProps.push(notes);
        return columnProps;
    }

    loadChannelMap(): void {
        AdminApiService.getChannelCodeNameMap((data: Map<string, string>) => {
            this.setState({channelNameMap: data});
        })
    }

    loadCpNameMap(): void {
        AdminApiService.getProductCodeCpNameMap((data: Map<string, string>) => {
            this.setState({cpNameMap: data});
        })
    }

    loadProductNameMap(): void {
        AdminApiService.getProductCodeNameMap("name", (data: Map<string, string>) => {
            this.setState({productNameMap: data});
        })
    }

    loadProductNoteMap(): void {
        AdminApiService.getProductCodeNameMap("note", (data: Map<string, string>) => {
            this.setState({productNoteMap: data});
        })
    }

    loadChannel(): void {
        AdminApiService.getChannelPagedList("", new Map<string, any>(), 0, 99999, (result: JtPagedList<Channel>) => {
            let data = result.data;
            this.setState({channelList: data});
        });
    }

    loadCP(): void {
        AdminApiService.getContentProviderPagedList("", new Map<string, any>(), "name", "asc",
            0, 99999, (result: JtPagedList<ContentProvider>) => {
                let data = result.data;
                this.setState({cpList: data});
            })
    }

    loadLastImportDatetime(): void {
        AdminApiService.getLastImportDatetime((datetime: string) => {
            this.setState({lastImportDatetime: datetime});
        });
    }

    render() {
        return <div>
            <JtDataTable title={"Product daily"}
                         columnProps={this.state.columnProps}
                         filterPanel={this.getFilterPanel()}
                         leftNode={this.leftNode()}
                         rightNode={this.rightNode()}
                         cellPadding={4}
                         footerColumnProps={this.state.footerColumnProps}
                         adapter={this.state.tableAdapter}/>
            <AdvertDataImportDialog ref={(element: any) => {
                this.importDialog = element;
            }}/>
            {this.getUpdateDialog()}
        </div>
    }

    leftNode(): ReactNode {
        return <FtRow cellHorizontalSpace={16}>
            <IconButton onClick={() => {
                this.props.history.goBack()
            }}><ArrowBack/></IconButton>
            {AppClientInfo.clientInfo.loginUser.role === "ADMIN" &&
            <Button color={"primary"} variant={"contained"}
                    style={{textTransform: "none"}}
                    onClick={() => this.importDialog.show(this.onFinishUpload)}>
                Import
            </Button>}
            <Button color={"secondary"} variant={"contained"}
                    style={{textTransform: "none"}}
                    onClick={() => {
                        if (AppClientInfo.clientInfo.loginUser.role == "CHANNEL_ADMIN") {
                            this.onExportChannelExcel();
                        } else {
                            this.onExportAdminExcel();
                        }
                    }}>Export
            </Button>
        </FtRow>
    }

    rightNode(): ReactNode {
        return <Typography variant="h6" style={{marginRight: 16}}>
            {"上次导入时间: " + this.state.lastImportDatetime}
        </Typography>
    }

    onExportAdminExcel() {
        let headers: any[] = [
            {key: 'datetime', title: 'Date'},
            {key: 'channelName', title: 'Channel name'},
            {key: 'cpName', title: 'CP name'},
            {key: 'productName', title: 'Product name'},
            {key: 'gaDailyActive', title: 'GA dau'},
            {key: 'adRequests', title: 'Requests'},
            {key: 'matchedRequests', title: 'Matched requests'},
            {key: 'coverage', title: 'Coverage'},
            {key: 'viewsCount', title: 'Impressions'},
            {key: 'clickCount', title: 'Clicks'},
            {key: 'estimatedIncome', title: 'Ad revenue($)'},
            {key: 'ctr', title: 'CTR'},
            {key: 'cpc', title: 'CPC'},
            {key: 'ecpm', title: 'ECPM'},
            {key: 'note', title: 'Product note'}
        ];
        let data: any[] = [];
        let dataList = this.state.tableAdapter.pagedList.data;
        let totalAdRequests = 0;
        let totalMatchedRequests = 0;
        let totalCoverage = 0;
        let totalGaDailyActive = 0;
        let totalViewsCount = 0;
        let totalClickCount = 0;
        let totalEstimatedIncome = 0;
        let totalCtr = 0;
        let totalCpc = 0;
        let totalEcpm = 0;
        dataList.forEach((advertData: AdvertData) => {
            let item = {
                datetime: advertData.datetime,
                channelName: this.state.channelNameMap.get(advertData.channelCode),
                cpName: this.state.cpNameMap.get(advertData.productCode),
                productName: this.state.productNameMap.get(advertData.productCode),
                gaDailyActive: advertData.gaDailyActive,
                adRequests: advertData.adRequests,
                matchedRequests: advertData.matchedRequests,
                coverage: advertData.coverage,
                viewsCount: advertData.viewsCount,
                clickCount: advertData.clickCount,
                estimatedIncome: advertData.estimatedIncome,
                ctr: advertData.ctr,
                cpc: advertData.cpc,
                ecpm: advertData.ecpm,
                note: this.state.productNoteMap.get(advertData.productCode) != null ? this.state.productNoteMap.get(advertData.productCode) : "",
            };
            data.push(item);

            totalAdRequests += advertData.adRequests;
            totalMatchedRequests += advertData.matchedRequests;
            totalCoverage += advertData.coverage;
            totalGaDailyActive += advertData.gaDailyActive;
            totalViewsCount += advertData.viewsCount;
            totalClickCount += advertData.clickCount;
            totalEstimatedIncome += advertData.estimatedIncome;
            totalCtr += advertData.ctr;
            totalCpc += advertData.cpc;
            totalEcpm += advertData.ecpm;
        })
        let endItem = {
            datetime: "Total",
            channelName: "",
            cpName: "",
            productName: "",
            gaDailyActive: totalGaDailyActive,
            adRequests: totalAdRequests,
            matchedRequests: totalMatchedRequests,
            coverage: (totalCoverage / dataList.length).toFixed(2),
            viewsCount: totalViewsCount,
            clickCount: totalClickCount,
            estimatedIncome: totalEstimatedIncome.toFixed(2),
            ctr: totalCtr.toFixed(2),
            cpc: totalCpc.toFixed(2),
            ecpm: totalEcpm.toFixed(2),
            note: "",
        };
        data.push(endItem);
        let date = new Date();
        let cellStyle: any[] = [{wpx: 80}, {wpx: 100}, {wpx: 100}, {wpx: 100}, {wpx: 60}, {wpx: 60}, {wpx: 60}, {wpx: 60}, {wpx: 60}, {wpx: 60}, {wpx: 60}, {wpx: 60}, {wpx: 60}, {wpx: 60}, {wpx: 60}]
        exportExcel(headers, data, cellStyle, "advert_daily_data_report_" + date.getTime() + ".xlsx");
    }

    onExportChannelExcel() {
        let headers: any[] = [
            {key: 'datetime', title: 'Date'},
            {key: 'productName', title: 'Product name'},
            {key: 'gaDailyActive', title: 'GA dau'},
            {key: 'adRequests', title: 'Requests'},
            {key: 'matchedRequests', title: 'Matched requests'},
            {key: 'coverage', title: 'Coverage'},
            {key: 'viewsCount', title: 'Impressions'},
            {key: 'clickCount', title: 'Clicks'},
            {key: 'channelEstimatedIncome', title: 'Ad revenue($)'},
            {key: 'ctr', title: 'CTR'},
            {key: 'channelCpc', title: 'CPC'},
            {key: 'channelEcpm', title: 'ECPM'},
            {key: 'note', title: 'Product note'}
        ];
        let data: any[] = [];
        let dataList = this.state.tableAdapter.pagedList.data;
        let totalAdRequests = 0;
        let totalMatchedRequests = 0;
        let totalCoverage = 0;
        let totalGaDailyActive = 0;
        let totalViewsCount = 0;
        let totalClickCount = 0;
        let totalChannelEstimatedIncome = 0;
        let totalCtr = 0;
        let totalChannelCpc = 0;
        let totalChannelEcpm = 0;
        dataList.forEach((advertData: AdvertData) => {
            let item = {
                datetime: advertData.datetime,
                productName: this.state.productNameMap.get(advertData.productCode),
                gaDailyActive: advertData.gaDailyActive,
                adRequests: advertData.adRequests,
                matchedRequests: advertData.matchedRequests,
                coverage: advertData.coverage,
                viewsCount: advertData.viewsCount,
                clickCount: advertData.clickCount,
                channelEstimatedIncome: advertData.channelEstimatedIncome,
                ctr: advertData.ctr,
                channelCpc: advertData.channelCpc,
                channelEcpm: advertData.channelEcpm,
                note: this.state.productNoteMap.get(advertData.productCode) != null ? this.state.productNoteMap.get(advertData.productCode) : "",
            };
            totalAdRequests += advertData.adRequests;
            totalMatchedRequests += advertData.matchedRequests;
            totalCoverage += advertData.coverage;
            totalGaDailyActive += advertData.gaDailyActive;
            totalViewsCount += advertData.viewsCount;
            totalClickCount += advertData.clickCount;
            totalChannelEstimatedIncome += advertData.channelEstimatedIncome;
            totalCtr += advertData.ctr;
            totalChannelCpc += advertData.channelCpc;
            totalChannelEcpm += advertData.channelEcpm;
            data.push(item);
        })
        let endItem = {
            datetime: "Total",
            productName: "",
            gaDailyActive: totalGaDailyActive,
            adRequests: totalAdRequests,
            matchedRequests: totalMatchedRequests,
            coverage: (totalCoverage / dataList.length).toFixed(2),
            viewsCount: totalViewsCount,
            clickCount: totalClickCount,
            channelEstimatedIncome: totalChannelEstimatedIncome.toFixed(2),
            ctr: totalCtr.toFixed(2),
            channelCpc: totalChannelCpc.toFixed(2),
            channelEcpm: totalChannelEcpm.toFixed(2),
            note: ""
        };
        data.push(endItem);
        let date = new Date();
        let cellStyle: any[] = [{wpx: 80}, {wpx: 60}, {wpx: 60}, {wpx: 60}, {wpx: 60}, {wpx: 60}, {wpx: 60}, {wpx: 60}, {wpx: 60}, {wpx: 60}, {wpx: 60}, {wpx: 60}, {wpx: 60}]
        exportExcel(headers, data, cellStyle, "advert_daily_data_report_" + date.getTime() + ".xlsx");
    }

    onFinishUpload() {
        FtSnackbars.showText("Import success");
        this.state.tableAdapter.changePageNo(0);
        AdminApiService.getLastImportDatetime((datetime: string) => {
            this.setState({lastImportDatetime: datetime});
        });
    }

    getFilterPanel(): ReactNode {
        return <FtRow cellHorizontalSpace={16} style={{margin: "0px 16px"}}>
            <FtTextField label={"Keyword"} style={{width: 200}}
                         onChange={(event) => {
                             let value = event.target.value;
                             this.state.tableAdapter.keyword = value ? value : "";
                             this.forceUpdate();
                         }} value={this.state.tableAdapter.keyword}/>
            {AppClientInfo.clientInfo.loginUser.role !== "CHANNEL_ADMIN" &&
            <Autocomplete options={this.state.channelList}
                          getOptionLabel={(option) => option.name + " - " + option.code}
                          renderInput={(params) => <TextField {...params} label={"Channel code"}/>}
                          onChange={(event, value) => {
                              this.state.tableAdapter.filterColumns.set("channelCode", value?.code)
                              this.state.tableAdapter.filterColumns.set("channelNameAndCode", value?.name + " - " + value?.code)
                              this.forceUpdate();
                          }}
                          inputValue={this.state.tableAdapter.getFilterColumnValue("channelNameAndCode")}
                          style={{width: 200}}/>}
            {AppClientInfo.clientInfo.loginUser.role !== "CHANNEL_ADMIN" &&
            <Autocomplete options={this.state.cpList}
                          getOptionLabel={(option) => option.name + " - " + option.code}
                          renderInput={(params) => <TextField {...params} label={"CP code"}/>}
                          onChange={(event, value) => {
                              this.state.tableAdapter.filterColumns.set("contentProviderId", value?.id)
                              this.state.tableAdapter.filterColumns.set("cpNameAndCode", value?.name + " - " + value?.code)
                              this.forceUpdate();
                          }}
                          inputValue={this.state.tableAdapter.getFilterColumnValue("cpNameAndCode")}
                          style={{width: 200}}/>}
            <TextField id="startDate"
                       label={"Start date"}
                       type="date"
                       style={{width: 150}}
                       value={this.state.tableAdapter.getFilterColumnValue("startDate")}
                       InputLabelProps={{shrink: true,}}
                       onChange={event => {
                           this.state.tableAdapter.filterColumns.set("startDate", event.target.value);
                           this.forceUpdate();
                       }}
            />
            <TextField id="endDate"
                       label={"End date"}
                       type="date"
                       style={{width: 150}}
                       value={this.state.tableAdapter.getFilterColumnValue("endDate")}
                       InputLabelProps={{shrink: true,}}
                       onChange={event => {
                           this.state.tableAdapter.filterColumns.set("endDate", event.target.value);
                           this.forceUpdate();
                       }}
            />
        </FtRow>
    }

    getUpdateDialog(): ReactNode {
        return <Dialog open={this.state.openDialog} PaperProps={{style: {width: 450}}}>
            <DialogTitle>
                <Typography>更新备注</Typography>
            </DialogTitle>
            <DialogContent style={{padding: "6px 24px"}} dividers>
                <FtTextField id={"code"} type={"text"} label="产品备注"
                             style={{width: "100%"}}
                             onChange={(event) => {
                                 this.setState({selectProductNote: event.target.value})
                             }}
                             value={this.state.selectProductNote}/>
            </DialogContent>
            <DialogActions style={{display: "flex", justifyContent: "space-between", padding: "16px 24px"}}>
                <Button onClick={() => {
                    this.setState({openDialog: false})
                }} variant={"contained"} color={"secondary"}>关闭</Button>
                <Button onClick={this.onSaveProductNote} variant={"contained"} color={"primary"}>保存</Button>
            </DialogActions>
        </Dialog>;
    }

    onSaveProductNote() {
        FtToast.showToastLoading();
        AdminApiService.saveProductNote(this.state.selectProductCode + "", this.state.selectProductNote + "", () => {
            FtToast.closeToast();
            this.setState({openDialog: false, selectProductNote: undefined, selectProductCode: undefined});
            this.loadProductNoteMap();
        });
    }
}
