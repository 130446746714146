import HttpService from "./HttpService";
import ApiResponse from "../core/ApiResponse";

export default class OpenApiService extends HttpService {

    static login(loginName: string, password: string, successFunction: (userId: string) => void, failFunction ?: (error: ApiResponse) => void): void {
        let params = {loginName: loginName, password: password};
        HttpService.postFormData("/open/sso/login", params, successFunction, failFunction ? failFunction : HttpService.handleError);
    }
}
