import * as React from "react";
import {CSSProperties, HTMLAttributes} from "react";
import {grey} from "@material-ui/core/colors";
import JtUtil from "./FtUtil";
import FtRow from "./FtRow";

export interface FtToolbarProp extends HTMLAttributes<HTMLDivElement> {
    leftNode?: React.ReactNode;
    centerNode?: React.ReactNode;
    rightNode?: React.ReactNode;
    position?: 'fixedBottom' | 'fixedTop' | 'sticky' | 'fixedLeft' | 'fixedRight';
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
}

export default class FtToolbar extends React.Component<FtToolbarProp, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        let styles: CSSProperties = {};
        styles.display = "flex";
        styles.marginTop = 0;
        styles.marginBottom = 0;
        styles.justifyContent = "space-between";
        styles.alignItems = "center";
        styles.flexWrap = "nowrap";
        styles.minHeight = 47;
        styles.paddingLeft = 4;
        styles.paddingRight = 4;
        styles.borderBottomColor=grey[300];
        styles.borderBottomStyle="solid";
        styles.borderBottomWidth=1;
        if (this.props.position == null) {

        } else if (this.props.position == "fixedTop") {
            styles.top = this.props.top ? this.props.top : 0;
            styles.position="fixed";
        } else if (this.props.position == null || this.props.position == "fixedBottom") {
            styles.bottom = this.props.bottom ? this.props.bottom : 0;
            styles.position="fixed";
        } else if (this.props.position == null || this.props.position == "fixedLeft") {
            styles.left = this.props.left ? this.props.left : 0;
            styles.position="fixed";
        } else if (this.props.position == null || this.props.position == "fixedRight") {
            styles.right = this.props.right ? this.props.right : 0;
            styles.position="fixed";
        }
        styles=JtUtil.mergeObject(styles,this.props.style);
        return <FtRow style={styles} cellWidthS={"3,1,3"} justifyContent={"space-between"}>
            {this.props.leftNode != null ? this.props.leftNode : <div />}
            {this.props.centerNode != null ? this.props.centerNode : <div />}
            {this.props.rightNode != null ? this.props.rightNode : <div />}
        </FtRow>;
    }
}