import * as React from "react";
import {ReactNode} from "react";
import {AppBar, Collapse, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {ExpandLess, ExpandMore, Menu} from "@material-ui/icons";
import {blue, grey} from "@material-ui/core/colors";
import FtMenuData from "./FtMenuData";
import JtComponent, {JtComponentState} from "./JtComponent";

export interface FtAdminFrameProp {
    topBar?: ReactNode;
    leftDrawerMenu?: Array<FtMenuData>;
    onClickLeftDrawerMenu?: (menuId: string) => void;
    onOpenLeftDrawer?: (isOpen: boolean) => void;
    leftDrawerWidth?: number;
    leftDrawerOpenDefault?: boolean;
}

export class FtAdminFrameState extends JtComponentState {
    showDrawer: boolean = true;
}

export default class FtAdminFrame extends JtComponent<FtAdminFrameProp, FtAdminFrameState> {
    constructor(props: any) {
        super(props);
        this.state = {showDrawer: this.props.leftDrawerOpenDefault != null ? this.props.leftDrawerOpenDefault : true};
        this.onClickMenuItem = this.onClickMenuItem.bind(this);
        this.onClickMenuItemExpand = this.onClickMenuItemExpand.bind(this);
    }

    render() {
        return <div>
            {this.props.topBar &&
            <AppBar position={"fixed"} style={{height: 40, backgroundColor: blue[800], color: grey[50]}}>
                <div style={{display: "flex"}}>
                    <IconButton size={"small"} onClick={() => {
                        this.setState({showDrawer: !this.state.showDrawer});
                        if (this.props.onOpenLeftDrawer)
                            this.props.onOpenLeftDrawer(!this.state.showDrawer);
                    }} color={"inherit"} style={{flexGrow: 1}}><Menu></Menu></IconButton>
                    {this.props.topBar}
                </div>
            </AppBar>}
            <Drawer anchor={"left"} variant={"persistent"} open={this.state.showDrawer} style={{top: 40}} PaperProps={{
                style: {
                    top: 40,
                    backgroundColor: blue[800],
                    color: grey[50],
                    width: this.props.leftDrawerWidth ? this.props.leftDrawerWidth : 160,
                    paddingLeft: 8,
                    boxSizing: "border-box",
                    height:"calc(100% - 40px)"
                }
            }}>
                <List key={"FtAdminFrameList"} component="nav">
                    {this.props.leftDrawerMenu && this.getMenuSection(this.props.leftDrawerMenu, 0)}
                </List>
            </Drawer>
            {this.props.children}
        </div>
    }

    private getMenuSection(memuList: Array<FtMenuData>, level: number) {
        const result: Array<ReactNode> = [];
        if (memuList && memuList.length > 0) {
            memuList.forEach((menu: FtMenuData, index: number) => {
                result.push(<ListItem key={index} style={{paddingTop: 4, paddingBottom: 4}} id={menu.menuId} button
                                      disableGutters={true} onClick={() => {
                    this.onClickMenuItem(menu);
                }}>
                    {menu.icon && <ListItemIcon style={{color: grey[50], minWidth: 28}}>
                        {menu.icon}
                    </ListItemIcon>}
                    {menu.primaryText && menu.primaryText.length > 0 &&
                    <ListItemText primary={menu.primaryText} secondary={menu.secondaryText} />}
                    {menu.subMenuList.length > 0 && ((menu.expand != null && menu.expand) ?
                        <ExpandLess onClick={(event) => {
                            this.onClickMenuItemExpand(menu);
                            event.stopPropagation();
                        }}/> : <ExpandMore onClick={(event) => {
                            this.onClickMenuItemExpand(menu);
                            event.stopPropagation();
                        }}/>)}
                </ListItem>);
                if (menu.subMenuList.length > 0) {
                    result.push(<Collapse in={menu.expand} timeout="auto" unmountOnExit>
                        <List key={"child-"+menu.menuId} component="div" disablePadding={true} style={{paddingLeft: (level + 1) * 16}}>
                            {this.getMenuSection(menu.subMenuList, level + 1)}
                        </List>
                    </Collapse>);
                }
            });
        }
        return result;
    }

    private onClickMenuItem(menu: FtMenuData) {
        if (menu.subMenuList.length > 0) {
            this.onClickMenuItemExpand(menu);
        } else if (this.props.onClickLeftDrawerMenu) {
            this.props.onClickLeftDrawerMenu(menu.menuId);
            this.setState({showDrawer: this.props.leftDrawerOpenDefault != null ? this.props.leftDrawerOpenDefault : true}, () => {
                if (this.props.onOpenLeftDrawer)
                    this.props.onOpenLeftDrawer(this.state.showDrawer);
            });
        }
    }

    private onClickMenuItemExpand(menu: FtMenuData) {
        menu.expand = !menu.expand;
        this.forceUpdate();
    }
}

