export default class JtState {
    static data: { [key: string]: any; } = {};

    public static put(key?: string, value?: any) {
        if (key && value)
            this.data[key] = value;
    }

    public static pop(key?: string): any {
        if (key) {
            const value = this.data[key];
            JtState.data[key] = null;
            return value;
        } else
            return null;
    }

    public static get(key?: string): any {
        if (key) {
            const value = JtState.data[key];
            return value;
        } else
            return null;
    }
}