
export default class ProductControl {
    id: number = 0;
    code: string = "";
    productId: number = 0;
    productCount: number = 0;
    spareProductId: number = 0;
    spareProductCount: number = 0;
    updateDatetimeText: string = "";
    updateUserName: string = "";
}
