import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import {RouteComponentProps} from "react-router";
import JtDataTable, {JtDataTableAdapter, JtDataTableColumnProps} from "../core/JtDataTable";
import FtToast from "../core/FtToast";
import JtState from "../core/JtState";
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    TextField,
    Tooltip,
    Typography
} from "@material-ui/core";
import AdminApiService from "../service/AdminApiService";
import JtPagedList from "../core/JtPagedList";
import FtTextField from "../core/FtTextField";
import FtRow from "../core/FtRow";
import FtUtil from "../core/FtUtil";
import {ArrowBack} from "@material-ui/icons";
import ProductControl from "../model/ProductControl";
import Product from "../model/Product";
import {Autocomplete} from "@material-ui/lab";

interface ProductControlPageProp extends RouteComponentProps {

}

export class ProductControlPageState {
    tableAdapter: TableAdapter = new TableAdapter();
    columnProps: Array<JtDataTableColumnProps> = [];
    openDialog: boolean = false;
    openTitle: string = "";
    update: ProductControl = new ProductControl;
    productList: Array<Product> = [];
}

class TableAdapter extends JtDataTableAdapter<ProductControl> {

    loadData(): void {
        FtToast.showToastLoading();
        AdminApiService.getProductControlPagedList(this.keyword, this.filterColumns, this.sortColumn ? this.sortColumn.propertyName : null,
            this.sortDirection, this.pagedList.pageNo, this.pagedList.pageSize,
            (result: JtPagedList<ProductControl>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
    resetSearch() {
        super.resetSearch();
    }
}

export default class ProductControlPage extends React.Component<ProductControlPageProp, ProductControlPageState> {
    constructor(props: any) {
        super(props);
        const columnProps: Array<JtDataTableColumnProps> = [];
        const column0: JtDataTableColumnProps = new JtDataTableColumnProps("code", "上量链接编号", "center");
        column0.renderCell = (rowData: object) => {
            const productControl = rowData as ProductControl;
            return <Tooltip title={"编辑"}>
                <Chip label={productControl.code} clickable
                      variant="outlined" color={"secondary"}
                      onClick={()=>{this.setState({openDialog: true, openTitle: "更新产品控制", update: productControl });}}/>
            </Tooltip>
        };
        columnProps.push(column0);
        const column2: JtDataTableColumnProps = new JtDataTableColumnProps("product.code", "上线产品", "center");
        columnProps.push(column2);
        const column3: JtDataTableColumnProps = new JtDataTableColumnProps("productCount", "上量额度", "center");
        columnProps.push(column3);
        const column4: JtDataTableColumnProps = new JtDataTableColumnProps("spareProduct.code", "备用产品", "center");
        columnProps.push(column4);
        const column5: JtDataTableColumnProps = new JtDataTableColumnProps("spareProductCount", "备用产品额度", "center");
        columnProps.push(column5);
        const column9: JtDataTableColumnProps = new JtDataTableColumnProps("updateUserName", "最后操作", "center");
        columnProps.push(column9);
        const column10: JtDataTableColumnProps = new JtDataTableColumnProps("updateDatetimeText", "最后操作时间", "center");
        columnProps.push(column10);

        let tableAdapter: TableAdapter;
        const cacheState: ProductControlPageState = JtState.pop("ProductControlPageState");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new TableAdapter();
        }
        tableAdapter.onUpdateData = ((pagedList: JtPagedList<any>) => {
            this.forceUpdate();
        });
        this.state = {
            tableAdapter: tableAdapter,
            columnProps: columnProps,
            openDialog: false,
            openTitle: "",
            update: new ProductControl(),
            productList: [],
        };
        this.getFilterPanel = this.getFilterPanel.bind(this);
        this.getUpdateDialog = this.getUpdateDialog.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.leftNode = this.leftNode.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onLoadProduct = this.onLoadProduct.bind(this);

    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
        this.onLoadProduct();
    }

    onLoadProduct(): void {
        AdminApiService.getProductPagedList("", new Map<string, any>(), null, null, 0, 99999,
            (result: JtPagedList<Product>) => {
                let data = result.data;
                this.setState({productList: data});
            });
    }

    render() {
        return <div>
            <JtDataTable title={"产品控制列表"} columnProps={this.state.columnProps}
                         filterPanel={this.getFilterPanel()}
                         leftNode={this.leftNode()}
                         adapter={this.state.tableAdapter} />
            {this.getUpdateDialog()}
        </div>
    }

    leftNode(): ReactNode {
        return <FtRow cellHorizontalSpace={16}>
            <IconButton onClick={()=>{this.props.history.goBack()}}><ArrowBack /></IconButton>
            <Button variant={"contained"} color={"primary"} onClick={()=>{
                this.setState({openDialog: true, openTitle: "添加产品控制", update: new ProductControl()});
            }}>添加产品控制</Button>
        </FtRow>;
    }

    getFilterPanel(): ReactNode {
        return <FtRow cellHorizontalSpace={16} style={{margin: "0px 16px"}}>
            <Autocomplete options={this.state.productList}
                          getOptionLabel={(option)=>option.code}
                          inputValue={this.state.tableAdapter.getFilterColumnValue("productAutocomplete")}
                          renderInput={(params) => <TextField {...params} label={"产品编号"}/>}
                          onChange={(event, value)=>{
                              this.state.tableAdapter.filterColumns.set("productId", value?.id)
                              this.state.tableAdapter.filterColumns.set("productAutocomplete", value?.code)
                              this.forceUpdate();
                          }}
                          style={{ width: 200 }}/>
        </FtRow>
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
        FtUtil.setProperty(this.state.update, event.target.id, event.target.value);
        this.forceUpdate();
    }

    getUpdateDialog(): ReactNode {
        return <Dialog open={this.state.openDialog} PaperProps={{style: {width: 450}}}>
            <DialogTitle>
                <Typography>{this.state.openTitle}</Typography>
            </DialogTitle>
            <DialogContent style={{padding: "6px 24px"}} dividers>
                <FtRow cellWidthS={"1"} cellHorizontalAlign={"center"}>
                    <FtTextField id={"code"} type={"text"} label="上量链接编号" disabled={this.state.update.id !== null && this.state.update.id > 0}
                                 style={{width: "100%"}} onChange={this.onChange}
                                 value={this.state.update.code}/>
                    <FtTextField id={"productId"} select type={"text"} label="上线产品"
                                 style={{width: "100%", textAlign: "left"}}
                                 value={this.state.update.productId}
                                 onChange={(event)=>{
                                     this.state.update.productId = +event.target.value;
                                     if (this.state.update.productId == this.state.update.spareProductId) {
                                         this.state.update.spareProductId = 0;
                                     }
                                     this.forceUpdate();
                                 }}>
                        {this.state.productList.map((product: Product)=>{
                            return <MenuItem key={product.id} value={product.id}>{product.code}</MenuItem>
                        })}
                    </FtTextField>
                    <FtTextField id={"productCount"} type={"text"} label="上线产品上量额度"
                                 style={{width: "100%"}} onChange={this.onChange}
                                 value={this.state.update.productCount}/>
                    <FtTextField id={"spareProductId"} select type={"text"} label="备用产品"
                                 style={{width: "100%", textAlign: "left"}}
                                 onChange={(event)=>{
                                     this.state.update.spareProductId = +event.target.value;
                                     this.forceUpdate();
                                 }}
                                 value={this.state.update.spareProductId}>
                        {this.state.productList.map((product: Product)=>{
                            if (product.id !== this.state.update.id) {
                                return <MenuItem key={product.id} value={product.id}>{product.code}</MenuItem>;
                            } else {
                                return null;
                            }
                        })}
                    </FtTextField>
                    <FtTextField id={"spareProductCount"} type={"text"} label="备用产品上量额度"
                                 style={{width: "100%"}} onChange={this.onChange}
                                 value={this.state.update.spareProductCount}/>
                </FtRow>
            </DialogContent>
            <DialogActions style={{display:"flex", justifyContent:"space-between", padding: "16px 24px"}}>
                <Button onClick={()=>{this.setState({openDialog: false})}} variant={"contained"} color={"secondary"}>关闭</Button>
                <Button onClick={this.onClickSave} variant={"contained"} color={"primary"}>保存</Button>
            </DialogActions>
        </Dialog>;
    }

    onClickSave() {
        FtToast.showToastLoading();
        AdminApiService.saveProductControl(this.state.update, (productControl: ProductControl)=>{
            FtToast.closeToast();
            this.setState({openDialog: false});
            this.state.tableAdapter.loadData();
        });
    }
}
