import * as React from "react";
import {
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    Typography,
    withStyles
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FtRow from "../core/FtRow";
import JtMessageDialog from "../core/JtMessageDialog";
import FtToast from "../core/FtToast";
import AdminApiService from "../service/AdminApiService";
import {grey} from "@material-ui/core/colors";
import {ArrowDownward} from "@material-ui/icons";
import FtFileUploadComp, {FileUploadResult} from "../core/FtFileUploadComp";
import AdvertData from "../model/AdvertData";
import FtSnackbars from "../core/FtSnackbars";
import ApiResponse from "../core/ApiResponse";

interface AdvertDataImportDialogProp {
}

export class AdvertDataImportDialogState {
    open: boolean = false;
    fileName: string = "";
    importDataList: Array<AdvertData> = [];
    saveSuccessFunction?: () => void;
}


export default class AdvertDataImportDialog extends React.Component<AdvertDataImportDialogProp, AdvertDataImportDialogState> {

    constructor(props: any) {
        super(props);
        this.state = {
            open: false,
            fileName: "",
            importDataList: [],
        };
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onFinishUpload = this.onFinishUpload.bind(this);
        this.onClickImportNow = this.onClickImportNow.bind(this);
        this.onReset = this.onReset.bind(this);
    }

    show(saveSuccessFunction: () => void): void {
        this.setState({
            open: true,
            fileName: "",
            importDataList: [],
            saveSuccessFunction: saveSuccessFunction
        });
    }

    componentDidMount(): void {

    }

    render() {
        return <Dialog maxWidth={"lg"} open={this.state.open} scroll={"paper"} PaperProps={{style: {width: 1200}}}>
            <DialogTitle style={{paddingTop: 0, paddingBottom: 0, paddingLeft: 24, paddingRight: 0}}>
                <FtRow justifyContent={"space-between"} cellHorizontalSpace={0} cellVerticalSpace={0}>
                    <Typography>Import advert data</Typography>
                    <IconButton onClick={this.onCancelClick}>
                        <CloseIcon/>
                    </IconButton>
                </FtRow>
            </DialogTitle>
            <DialogContent style={{paddingTop: 0, paddingBottom: 24}} dividers>
                {this.getContentHeaderComp()}
                {this.getContentBodyComp()}
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant={"outlined"} color={"primary"} style={{textTransform: "none"}}
                        onClick={this.onCancelClick}>Cancel</Button>
                <Button variant={"contained"} color={"primary"} style={{textTransform: "none"}}
                        onClick={this.onClickImportNow}>Import</Button>
            </DialogActions>
        </Dialog>;
    }

    private onCancelClick(): void {
        if (this.state.fileName.trim().length == 0) {
            this.setState({open: false});
        } else {
            JtMessageDialog.jtMessageDialog.openDialog("Are you sure to close?", () => {
                this.setState({open: false});
            });
        }
    }

    private onClickImportNow() {
        FtToast.showToastLoading("Start import", 120000);
        AdminApiService.saveAdvertData(this.state.importDataList, (response: ApiResponse) => {
            FtToast.closeToast();
            if (response.message) {
                FtSnackbars.showText(response.message);
            }
            this.setState({open: false});
            if (this.state.saveSuccessFunction) {
                this.state.saveSuccessFunction();
            }
        });
    }

    private onReset() {
        this.setState({fileName: "", importDataList: []})
    }

    private onFinishUpload(results: FileUploadResult) {
        this.setState({fileName: results.fileName, importDataList: results.data})
    }

    private getContentHeaderComp() {
        return <div style={{display: "flex"}}>
            {this.state.fileName.trim().length == 0 &&
            <FtRow cellHorizontalSpace={8} cellVerticalSpace={0}>
                <FtFileUploadComp fileType={".xls,.xlsx"} label={"Choose File"}
                                  uploadServiceFunction={AdminApiService.importProductFile}
                                  onFinish={this.onFinishUpload}/>
            </FtRow>}
            {this.state.fileName.trim().length > 0 &&
            <FtRow cellHorizontalSpace={8} cellVerticalSpace={0} style={{paddingTop: 16, paddingBottom: 16}}>
                <Typography>{this.state.fileName}</Typography>
                <Button variant={"contained"} color={"secondary"}
                        style={{padding: 4, textTransform: "none"}} size={"small"}
                        onClick={this.onReset}>Reset</Button>
            </FtRow>}
            <div style={{width: 32}}/>
            <FtRow cellHorizontalSpace={0} cellVerticalSpace={0}>
                <a href={"excel/advert_data_template.xlsx?ran=" + new Date().getTime()} target={"_blank"}
                   style={{textDecoration: "underline"}}>Download an example
                    <IconButton style={{padding: 0, color: grey[500]}} disabled={true}>
                        <ArrowDownward/>
                    </IconButton>
                </a>
            </FtRow>
        </div>
    }

    private getContentBodyComp() {
        if (this.state.importDataList.length > 0) {
            return <FtRow cellWidthS={"1"} cellHorizontalSpace={0} cellVerticalSpace={0}>
                <Typography variant={"h6"} color={"primary"}>
                    Total: {this.state.importDataList.length} datas to be imported
                </Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align={"left"} style={{backgroundColor: grey[200]}}>Line#</StyledTableCell>
                            <StyledTableCell align={"left"}>Date</StyledTableCell>
                            <StyledTableCell align={"left"}>Product code</StyledTableCell>
                            <StyledTableCell align={"left"}>GA dau</StyledTableCell>
                            <StyledTableCell align={"left"}>Requests</StyledTableCell>
                            <StyledTableCell align={"left"}>Matched requests</StyledTableCell>
                            <StyledTableCell align={"left"}>Impressions</StyledTableCell>
                            <StyledTableCell align={"left"}>Clicks</StyledTableCell>
                            <StyledTableCell align={"left"}>Ad revenue($)</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.importDataList != null && this.state.importDataList.length > 0 &&
                        this.state.importDataList.map((tableRow: AdvertData, index: number) => {
                            return <TableRow>
                                <TableCell align={"left"}>{index + 1}</TableCell>
                                <TableCell align={"left"}>{tableRow.datetime}</TableCell>
                                <TableCell align={"left"}>{tableRow.productCode}</TableCell>
                                <TableCell align={"left"}>{tableRow.gaDailyActive}</TableCell>
                                <TableCell align={"left"}>{tableRow.adRequests}</TableCell>
                                <TableCell align={"left"}>{tableRow.matchedRequests}</TableCell>
                                <TableCell align={"left"}>{tableRow.viewsCount}</TableCell>
                                <TableCell align={"left"}>{tableRow.clickCount}</TableCell>
                                <TableCell align={"left"}>{tableRow.estimatedIncome}</TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </FtRow>
        }
        return null;
    }
}

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: grey[200],
        }
    }),
)(TableCell);
