
export default class User {
    id: number = 0;
    loginName: string = "";
    password: string = "";
    createDatetimeText: string = "";
    role: string = "";
    objectCode: string = "";
    contacts: string = "";
    contactMobile: string = "";
    loginTimes: number = 0;

    static verifyNewUser(user: User): string {
        if ((user.contacts != null && user.contacts.trim().length > 0) ||
            (user.contactMobile != null && user.contactMobile.trim().length > 0)) {
            if (user.loginName == null || user.loginName.trim().length == 0) {
                return "登录账号不能为空";
            }
        }
        if (user.loginName != null && user.loginName.trim().length > 0) {
            if (user.password == null || user.password.trim().length == 0) {
                return "登录密码不能为空";
            }
        }
        if (user.objectCode != null && user.objectCode.trim().length > 0) {
            if (user.loginName == null || user.loginName.trim().length == 0) {
                return "登录账号不能为空";
            }
        }
        return "";
    }
}
