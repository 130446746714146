import ContentProvider from "./ContentProvider";

export default class Product {
    id: number = 0;
    code: string = "";
    name: string = "";
    contentProviderId: number = 0;
    channelCode: String = "";
    contentProvider: ContentProvider = new ContentProvider;
    productUrl: string = "";
    productType: string = "";
    advertType: string = "";
    productStatus: EProductStatus = EProductStatus.UNDER_DECLARATION;
    productStatusText: string = "";
    createDatetimeText: string = "";
    updateDatetimeText: string = "";
    updateUserName: string = "";
    note: string = "";
    productNote: string = "";
    dataLastUpdateTime: string = "";
}

export enum EProductStatus {
    UNDER_DECLARATION = "UNDER_DECLARATION",
    ONLINE = "ONLINE",
    OFFLINE = "OFFLINE",
}

export enum EForwardType {
    PRODUCT_ID = "PRODUCT_ID",
    WEIGHT = "WEIGHT",
}
