import * as React from "react";
import {Button} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

export class JtMessageDialogProps {

}

export class JtMessageDialogState {
    open: boolean = false;
    content?: string | React.ReactNode;
    leftButtonLabel?: string = "取消";
    onLeftButtonClick?: () => void;
    rightButtonLabel?: string =  "确定";
    onRightButtonClick?: () => void;
}

export default class JtMessageDialog extends React.Component<JtMessageDialogProps, JtMessageDialogState> {
    static jtMessageDialog: JtMessageDialog;

    constructor(props: any) {
        super(props);
        this.state = {open: false};
        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.onLeftButtonClick = this.onLeftButtonClick.bind(this);
        this.onRightButtonClick = this.onRightButtonClick.bind(this);
    }

    openDialog(content: string | React.ReactNode, onRightButtonClick?: () => void, onLeftButtonClick?: () => void, rightButtonLabel: string = "确定", leftButtonLabel: string = "取消") {
        this.setState({
            open: true,
            content: content,
            rightButtonLabel: rightButtonLabel,
            onRightButtonClick: onRightButtonClick,
            leftButtonLabel: leftButtonLabel,
            onLeftButtonClick: onLeftButtonClick
        });
    }

    closeDialog() {
        this.setState({open: false});
    }

    onLeftButtonClick() {
        this.closeDialog();
        if (this.state.onLeftButtonClick){
            this.state.onLeftButtonClick();
        }
    }

    onRightButtonClick() {
        this.closeDialog();
        if (this.state.onRightButtonClick) {
            this.state.onRightButtonClick();
        }
    }

    render() {
        const actions: Array<JSX.Element> =[];
        if (this.state.leftButtonLabel != null && this.state.leftButtonLabel.length > 0)
            actions.push(<Button variant={"outlined"}
                onClick={this.onLeftButtonClick} style={{float: "left"}}>{this.state.leftButtonLabel}</Button>);
        if (this.state.rightButtonLabel != null && this.state.rightButtonLabel.length > 0)
            actions.push(<Button variant={"contained"} color={"primary"} onClick={this.onRightButtonClick}>{this.state.rightButtonLabel}</Button>);
        return (
            <Dialog open={this.state.open}>
                <DialogContent style={{margin:8}}>
                    {this.state.content}
                </DialogContent>
                <DialogActions style={{display:"flex",justifyContent:"space-between",margin:8}}>
                    {actions}
                </DialogActions>
            </Dialog>
        );
    }
};