import * as React from "react";
import * as XLSX from 'xlsx';
import {theme} from "../Theme";
import GameOperationData from "../model/GameOperationData";

interface GameOperationDataPageProp {
    loadExcelData: (gameOperationDateList: Array<GameOperationData>) => void;
}

const importsExcel = (event: any) => {
    //使用promise导入
    return new Promise((resolve, reject) => {
        // 获取上传的文件对象
        const files = event.target.files; //获取里面的所有文件
        // 通过FileReader对象读取文件
        const fileReader = new FileReader();

        fileReader.onload = event => { //异步操作  excel文件加载完成以后触发
            try {
                const result = event.target?.result;
                // 以二进制流方式读取得到整份excel表格对象
                const workbook = XLSX.read(result, {type: 'binary'});
                let data: Array<any> = []; // 存储获取到的数据
                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                for (const sheet in workbook.Sheets) {
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet], {header: 1}));
                    }
                }
                if (data.length > 0) {
                    data.splice(0, 1);
                }
                resolve(data);//导出数据
            } catch (e) {
                // 这里可以抛出文件类型错误不正确的相关提示
                reject("失败");//导出失败
            }
        };
        // 以二进制方式打开文件
        fileReader.readAsBinaryString(files[0]);
    })
}

const exportExcel = (headers: any[], data: any[], fileName: string) => {

    const _headers = headers
        .map((item, i) => Object.assign({}, {
            key: item.key,
            title: item.title,
            position: String.fromCharCode(65 + i) + 1
        }))
        .reduce((prev, next) => Object.assign({}, prev, {[next.position]: {key: next.key, v: next.title}}), {});
    const _data = data
        .map((item, i) => headers.map((key, j) => Object.assign({}, {
            content: item[key.key],
            position: String.fromCharCode(65 + j) + (i + 2)
        })))
        // 对刚才的结果进行降维处理（二维数组变成一维数组）
        .reduce((prev, next) => prev.concat(next))
        // 转换成 worksheet 需要的结构
        .reduce((prev, next) => Object.assign({}, prev, {[next.position]: {v: next.content}}), {});

// 合并 headers 和 data
    const output = Object.assign({}, _headers, _data);
// 获取所有单元格的位置
    const outputPos = Object.keys(output);
// 计算出范围 ,["A1",..., "H2"]
    const ref = `${outputPos[0]}:${outputPos[outputPos.length - 1]}`;
// 构建 workbook 对象
    const wb = {
        SheetNames: ['mySheet'],
        Sheets: {
            mySheet: Object.assign(
                {},
                output,
                {
                    '!ref': ref,
                    '!cols': [{wpx: 45}, {wpx: 100}, {wpx: 200}, {wpx: 80}, {wpx: 150}, {wpx: 100}, {wpx: 300}, {wpx: 300}],
                },
            ),
        },
    };
    XLSX.writeFile(wb, fileName);
}
export default class ImportGameOperationDataComp extends React.Component<GameOperationDataPageProp, any> {

    constructor(props: any) {
        super(props);
    }

    componentDidMount(): void {

    }

    render() {
        return <div>
            <input type="file" accept=".xls,.xlsx" style={{width: 0, height: 0, opacity: 0}}
                   id="fileUploadInput"
                   onChange={(e) => {
                       const _this = this;
                       importsExcel(e).then(function (data) {
                           _this.actionList(data);
                       }, function (data) {
                           console.log(data);
                       })
                   }}/>
            <label htmlFor="fileUploadInput"
                   style={{
                       alignItems: "center", backgroundColor: theme.palette.primary.main,
                       color: "white", padding: "6px 12px", borderRadius: 5, cursor: "pointer"
                   }}>导入</label>
        </div>
    }

    actionList(data: any) {
        let gameOperationDateList: Array<GameOperationData> = [];
        let result: Array<any> = data as Array<any>;
        if (result && result.length > 0) {
            result.forEach((items: Array<string>) => {
                let gameOperationData: GameOperationData = new GameOperationData();
                // 商户编码	CP编码	应用编码	应用名称	Aries 单号	金额	国家	币种	计费方式	完成时间
                gameOperationData.code = items[0];
                gameOperationData.contentProviderCode = items[1];
                gameOperationData.appId = items[2];
                gameOperationData.appName = items[3];
                gameOperationData.ariesCode = this.substring(items[4]);
                gameOperationData.money = +items[5];
                gameOperationData.country = items[6];
                gameOperationData.coinType = items[7];
                gameOperationData.calculateType = items[8];
                gameOperationData.finishedDate = this.substring(items[9]);
                gameOperationDateList.push(gameOperationData);
            });
        }
        this.props.loadExcelData(gameOperationDateList);
    }

    substring(str: string) {
        if (str !== null && str.trim().length > 0) {
            if (str.startsWith("'")) {
                return str.substring(1);
            }
            return str;
        }
        return "";
    }
}
export {exportExcel}
