import * as React from "react";
import FtMenuData from "../core/FtMenuData";
import ClosedCaptionIcon from '@material-ui/icons/ClosedCaption';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import AssessmentIcon from '@material-ui/icons/Assessment';
import DomainIcon from '@material-ui/icons/Domain';

export const enum EMenuitem {
    CP_ADMIN = "CP_ADMIN",
    PRODUCT_ADMIN = "PRODUCT_ADMIN",
    PRODUCT_CONTROL_ADMIN = "PRODUCT_CONTROL_ADMIN",
    DAILY_OPERATION_DATA = "DAILY_OPERATION_DATA",
    GAME_OPERATION_DATA = "GAME_OPERATION_DATA",
    GAME_OPERATION_RATE = "GAME_OPERATION_RATE",
    CHANNEL_ADMIN = "CHANNEL_ADMIN",
    ADVERT_DATA_SUMMARY = "ADVERT_DATA_SUMMARY",
    ADVERT_DAILY_DATA_REPORT = "ADVERT_DAILY_DATA_REPORT",
}

export const adminMenuData: Array<FtMenuData> = [
    {
        menuId: EMenuitem.CP_ADMIN,
        primaryText: "CP管理",
        icon: <ClosedCaptionIcon/>,
        expand: true,
        subMenuList: []
    },
    {
        menuId: EMenuitem.PRODUCT_ADMIN,
        primaryText: "产品管理",
        icon: <ViewComfyIcon/>,
        expand: true,
        subMenuList: []
    },
    // {
    //     menuId: EMenuitem.PRODUCT_CONTROL_ADMIN,
    //     primaryText: "产品控制",
    //     icon: <SettingsIcon/>,
    //     expand: true,
    //     subMenuList: []
    // },
    {
        menuId: EMenuitem.CHANNEL_ADMIN,
        primaryText: "渠道管理",
        icon: <DomainIcon/>,
        expand: true,
        subMenuList: []
    },
    // {
    //     menuId: EMenuitem.DAILY_OPERATION_DATA,
    //     primaryText: "每日运营数据",
    //     icon: <AssessmentIcon/>,
    //     expand: true,
    //     subMenuList: []
    // },
    {
        menuId: "gameData",
        primaryText: "游戏数据",
        icon: <AssessmentIcon/>,
        expand: true,
        subMenuList: [
            {
                menuId: EMenuitem.GAME_OPERATION_DATA,
                primaryText: "计费流水",
                icon: <AssessmentIcon/>,
                expand: true,
                subMenuList: []
            },
            {
                menuId: EMenuitem.GAME_OPERATION_RATE,
                primaryText: "数据查询",
                icon: <AssessmentIcon/>,
                expand: true,
                subMenuList: []
            }
        ]
    }, {
        menuId: "advertiseData",
        primaryText: "广告数据",
        icon: <AssessmentIcon/>,
        expand: true,
        subMenuList: [
            {
                menuId: EMenuitem.ADVERT_DATA_SUMMARY,
                primaryText: "数据汇总",
                icon: <AssessmentIcon/>,
                expand: true,
                subMenuList: []
            },
            {
                menuId: EMenuitem.ADVERT_DAILY_DATA_REPORT,
                primaryText: "产品日报",
                icon: <AssessmentIcon/>,
                expand: true,
                subMenuList: []
            }
        ]
    }
];
export const adAdminMenuData: Array<FtMenuData> = [
    {
        menuId: EMenuitem.PRODUCT_ADMIN,
        primaryText: "产品管理",
        icon: <ViewComfyIcon/>,
        expand: true,
        subMenuList: []
    }, {
        menuId: EMenuitem.CHANNEL_ADMIN,
        primaryText: "渠道管理",
        icon: <DomainIcon/>,
        expand: true,
        subMenuList: []
    }, {
        menuId: "advertiseData",
        primaryText: "广告数据",
        icon: <AssessmentIcon/>,
        expand: true,
        subMenuList: [
            {
                menuId: EMenuitem.ADVERT_DATA_SUMMARY,
                primaryText: "数据汇总",
                icon: <AssessmentIcon/>,
                expand: true,
                subMenuList: []
            },
            {
                menuId: EMenuitem.ADVERT_DAILY_DATA_REPORT,
                primaryText: "产品日报",
                icon: <AssessmentIcon/>,
                expand: true,
                subMenuList: []
            }
        ]
    }
];
export const channelMenuData: Array<FtMenuData> = [
    {
        menuId: "advertiseData",
        primaryText: "广告数据",
        icon: <AssessmentIcon/>,
        expand: true,
        subMenuList: [
            {
                menuId: EMenuitem.ADVERT_DATA_SUMMARY,
                primaryText: "数据汇总",
                icon: <AssessmentIcon/>,
                expand: true,
                subMenuList: []
            },
            {
                menuId: EMenuitem.ADVERT_DAILY_DATA_REPORT,
                primaryText: "产品日报",
                icon: <AssessmentIcon/>,
                expand: true,
                subMenuList: []
            }
        ]
    }
];
export const contentProviderMenuData: Array<FtMenuData> = [

];
