import * as React from "react";
import FtSnackbars from "./FtSnackbars";
import FtToast from "./FtToast";
import {theme} from "../Theme";
import {Button} from "@material-ui/core";

export interface FtFileUploadCompProp {
    label?: string;
    onFinish: (data: FileUploadResult) => void;
    uploadServiceFunction: (file: any, successFunction: (data: any) => void,
                            progressFunction: (percent: number) => void, failFunction: (error: any) => void) => void;
    fileType?: string;
}

export class FtFileUploadCompState {
    uploadPercentageArray: Array<number> = [];
}

export interface FileUploadResult {
    data: Array<any>;
    fileName: string;
}

export default class FtFileUploadComp extends React.Component<FtFileUploadCompProp, FtFileUploadCompState> {
    inputUploadFile!: HTMLInputElement;

    constructor(props: any) {
        super(props);
        this.state = {uploadPercentageArray: []};
        this.onFileChange = this.onFileChange.bind(this);
    }

    onFileChange(event: any) {
        const fileList: FileList = event.target.files;
        if (fileList != null && fileList.length > 0) {
            const currentCount = this.state.uploadPercentageArray.length;
            let index = 0;
            let file = fileList.item(index);
            let fileSize = 10 * 1024 * 1024;
            if (file && file.size >= fileSize) {
                FtSnackbars.jtSnackbars.show("请上传小于10M的文件");
            } else {
                FtToast.showToastLoading("正在上传", 90000);
                this.props.uploadServiceFunction(file, (data: FileUploadResult) => {
                    FtToast.closeToast();
                    if (this.inputUploadFile.form) {
                        this.inputUploadFile.form.reset();
                    }
                    this.props.onFinish(data);
                }, (percent: number) => {
                    this.state.uploadPercentageArray[currentCount + index] = Math.floor(percent);
                }, (error: any) => {
                    FtToast.closeToast();
                    if (error.responseText) {
                        FtSnackbars.jtSnackbars.show(error.responseText);
                    }
                    if (this.inputUploadFile.form) {
                        this.inputUploadFile.form.reset();
                    }
                    this.setState({uploadPercentageArray: []});
                });
            }
        }
    }

    render() {
        return <div style={{margin: "16px 0px"}}>
            <form style={{height: 0}}>
                <input type="file" style={{width: 0, height: 0, opacity: 0}}
                       multiple={false} name="uploadfile"
                       id="fileUploadInput" accept={this.props.fileType}
                       ref={(element: any) => {
                           this.inputUploadFile = element;
                       }}
                       onChange={this.onFileChange}/>
            </form>
            <Button variant={"contained"} color={"primary"} size={"small"}
                    style={{textTransform: "none"}}
                    children={<label htmlFor="fileUploadInput">{this.props.label ? this.props.label : "导入"}</label>}/>
        </div>;
    }
}
