import * as React from "react";
import {ChangeEvent, CSSProperties, ReactNode} from "react";
import {Paper, TableContainer, TableFooter, TableSortLabel, WithTheme} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import withTheme from "@material-ui/core/styles/withTheme";
import {Refresh} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import JtPagedList from "./JtPagedList";
import FtUtil from "./FtUtil";
import FtToolbar from "./FtToolbar";
import FtDivFlex from "./FtDivFlex";
import {EFtSortDirection} from "./EFtSortDirection";


export class JtDataTableAdapter<M> {
    pagedList: JtPagedList<M> = new JtPagedList<M>();
    onUpdateData?: (pagedList: JtPagedList<any>) => void;
    keyword: string = "";
    filterColumns: Map<string, any> = new Map<string, any>();
    sortColumn?: JtDataTableColumnProps;
    sortDirection: EFtSortDirection = EFtSortDirection.asc;
    selectedRow: Array<M> = [];


    resetSearch() {
        this.keyword = "";
        this.filterColumns.clear();
        this.selectedRow = [];
    }

    getFilterColumnValue(columnName: string): string {
        const value = this.filterColumns.get(columnName);
        if (value == null || value == undefined)
            return "";
        else
            return value;
    }

    filter(keyword?: string, filterColumns?: Map<string, any>) {
        if (keyword)
            this.keyword = keyword;
        if (filterColumns)
            this.filterColumns = filterColumns;
        this.loadData();
    };

    loadData() {

    }

    clearSelection() {
        this.selectedRow = [];
    }

    changePageNo(newPageNo: number) {
        this.pagedList.pageNo = newPageNo;
        this.loadData();
        this.selectedRow = [];
    };

    changePageSize(newPageSize: number) {
        this.pagedList.pageNo = 0;
        this.pagedList.pageSize = newPageSize;
        this.loadData();
        this.selectedRow = [];
    };

    sort(sortColumn: Array<{ propertyName: string, value: EFtSortDirection }>) {

    };

    removeRow(rowData: M) {

    }

    fireUpdateData() {
        if (this.onUpdateData)
            this.onUpdateData(this.pagedList);
    }

    isSame(rowData: M, item: M): boolean {
        return rowData === item;
    }
}

export class JtDataTableColumnProps {
    label: string | ReactNode = "";
    cellWidth: number | string = 0;
    sortable: boolean = false;
    filterable: boolean = false;
    align: "left" | "center" | "right" = "left";
    propertyName: string = "";
    onClickCell?: (rowData: object, rowIndex: number, columnValue: any) => void;
    renderCell?: (rowData: object, rowIndex: number, columnValue: any) => ReactNode;
    renderHeader?: (columnProps: JtDataTableColumnProps) => ReactNode;
    cellStyle?: (rowData: object) => CSSProperties;

    constructor(propertyName: string, label: string | ReactNode = "", align: "left" | "center" | "right" = "left", cellWidth: number | string = 0) {
        this.propertyName = propertyName;
        this.label = label;
        this.align = align;
        this.cellWidth = cellWidth;
    }
}

export interface JtDataTableProp<M> extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, WithTheme {
    title?: string;
    columnProps: Array<JtDataTableColumnProps>;
    footerColumnProps?: Array<string | ReactNode>;
    selectMode?: "single" | "multiple";
    onHighlightRow?: (rowData: object, rowIndex: number) => void;
    adapter: JtDataTableAdapter<M>;
    filterPanel?: ReactNode;
    leftNode?: ReactNode;
    rightNode?: ReactNode;
    cellPadding?: number;
}

export class JtDataTableState {
    highlightRowIndex: number = -1;
}

class JtDataTable<M> extends React.Component<JtDataTableProp<M>, JtDataTableState> {
    constructor(props: any) {
        super(props);
        this.state = {highlightRowIndex: -1};
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.isRowSelected = this.isRowSelected.bind(this);
        this.getToolbar = this.getToolbar.bind(this);
        this.onClickSearch = this.onClickSearch.bind(this);
        this.onClickReset = this.onClickReset.bind(this);
        this.onClickHeader = this.onClickHeader.bind(this);
        this.onClickRow = this.onClickRow.bind(this);
    }

    render() {
        let styles: CSSProperties = {};
        styles = FtUtil.mergeObject(styles, this.props.style);
        return <Paper style={styles}>
            {this.getToolbar()}
            {this.props.filterPanel && <FtDivFlex style={{alignItems: "flex-end"}}>
                <div> {this.props.filterPanel}</div>
                <FtDivFlex style={{marginBottom: 6}}>
                    <ButtonGroup color="primary" variant={"outlined"}>
                        <Button onClick={this.onClickReset}>重置</Button>
                        <Button onClick={this.onClickSearch}>搜索</Button>
                    </ButtonGroup>
                </FtDivFlex>
            </FtDivFlex>}
            {/*<TableContainer style={{maxHeight: 500}}>*/}
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow key={"TableHead"}>
                            {this.props.columnProps && this.props.columnProps.map((columnProps: JtDataTableColumnProps) => {
                                return <TableCell key={columnProps.propertyName} align={columnProps.align}
                                                  style={{
                                                      padding: this.props.cellPadding,
                                                      width: columnProps.cellWidth != "" && columnProps.cellWidth > 0 ? columnProps.cellWidth : undefined
                                                  }}>
                                    {this.getHeaderCell(columnProps)}
                                </TableCell>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.adapter.pagedList && this.props.adapter.pagedList.data &&
                        this.props.adapter.pagedList.data.map((rowData: any, rowIndex: number) => {
                            return <TableRow key={rowIndex} hover onClick={() => {
                                this.onClickRow(rowData, rowIndex);
                            }} selected={rowIndex == this.state.highlightRowIndex}>
                                {this.props.columnProps && this.props.columnProps.map((columnProps: JtDataTableColumnProps, colIndex: number) => {
                                    const cellValue: any = FtUtil.getProperty(rowData, columnProps.propertyName);
                                    let cellStyle: CSSProperties = {padding: this.props.cellPadding};
                                    return <TableCell key={columnProps.propertyName + "-" + rowIndex}
                                                      style={columnProps.cellStyle ? FtUtil.mergeObject(cellStyle, columnProps.cellStyle(rowData)) : cellStyle}
                                                      align={columnProps.align} onClick={(event: any) => {
                                        if (columnProps.onClickCell) {
                                            event.preventDefault();
                                            columnProps.onClickCell(rowData, rowIndex, cellValue);
                                        }
                                    }}>
                                        {columnProps.renderCell ? columnProps.renderCell(rowData, rowIndex, cellValue) : cellValue}
                                    </TableCell>
                                })}
                            </TableRow>;
                        })}
                    </TableBody>
                    {this.props.footerColumnProps && this.props.footerColumnProps.length > 0 &&
                    <TableFooter>
                        <TableRow key={"TableFooter"}>
                            {this.props.footerColumnProps.map((column: string | ReactNode) => {
                                return <TableCell style={{padding: this.props.cellPadding}}>
                                    {column}
                                </TableCell>
                            })}
                        </TableRow>
                    </TableFooter>}
                </Table>
            </TableContainer>
            <TablePagination rowsPerPageOptions={[10, 20, 50, 100, 200, 500]} component="div"
                             style={{backgroundColor: "#fafafa", borderTop: "1px solid rgba(224, 224, 224, 1)"}}
                             count={this.props.adapter.pagedList.totalCount}
                             rowsPerPage={this.props.adapter.pagedList.pageSize}
                             page={this.props.adapter.pagedList.pageNo} backIconButtonProps={{
                'aria-label': 'Previous Page',
            }}
                             nextIconButtonProps={{
                                 'aria-label': 'Next Page',
                             }}
                             onPageChange={this.handleChangePage}
                             onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
        </Paper>;
    }

    getHeaderCell(columnProps: JtDataTableColumnProps) {
        if (columnProps.sortable) {
            if (columnProps.renderHeader) {
                return <TableSortLabel active={this.props.adapter.sortColumn === columnProps}
                                       direction={this.props.adapter.sortDirection}
                                       onClick={(event: any) => {
                                           this.onClickHeader(columnProps)
                                       }}>{columnProps.renderHeader(columnProps)}</TableSortLabel>
            } else {
                return <TableSortLabel active={this.props.adapter.sortColumn === columnProps}
                                       direction={this.props.adapter.sortDirection}
                                       onClick={(event: any) => {
                                           this.onClickHeader(columnProps)
                                       }}>{columnProps.label}</TableSortLabel>
            }
        } else {
            return columnProps.label;
        }
    }

    onClickRow(rowData: any, rowIndex: number) {
        if (this.props.onHighlightRow) {
            this.setState({highlightRowIndex: rowIndex}, () => {
                if (this.props.onHighlightRow)
                    this.props.onHighlightRow(rowData, rowIndex);
            });
        }
    }

    onClickHeader(columnProps: JtDataTableColumnProps) {
        if (this.props.adapter.sortColumn === columnProps) {
            if (this.props.adapter.sortDirection == EFtSortDirection.asc)
                this.props.adapter.sortDirection = EFtSortDirection.desc;
            else
                this.props.adapter.sortDirection = EFtSortDirection.asc;
        } else {
            this.props.adapter.sortColumn = columnProps;
            this.props.adapter.sortDirection = EFtSortDirection.asc;
        }
        this.props.adapter.changePageNo(0);
    }

    onClickReset() {
        this.props.adapter.resetSearch();
        this.props.adapter.changePageNo(0);
    }

    onClickSearch() {
        this.props.adapter.changePageNo(0);
    }

    private getToolbar(): ReactNode {
        const {theme} = this.props;
        const numSelected = this.props.adapter.selectedRow.length;
        const toolbarStyle: CSSProperties = {display: "flex", justifyContent: "space-between"};
        if (numSelected > 0) {
            toolbarStyle.backgroundColor = theme.palette.secondary.light;
        }
        return <FtToolbar leftNode={this.props.leftNode ? this.props.leftNode : <div/>}
                          centerNode={<Typography variant="h5" id="tableTitle">
                              {this.props.title}
                          </Typography>}
                          rightNode={<div style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                              {this.props.rightNode ? this.props.rightNode : <div/>}
                              <Button endIcon={<Refresh/>} color={"secondary"} onClick={() => {
                                  this.props.adapter.changePageNo(0);
                              }}>刷新</Button>
                          </div>}>
        </FtToolbar>;
    }

    private handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, page: number) {
        this.props.adapter.changePageNo(page);
    }

    private handleChangeRowsPerPage(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        this.props.adapter.changePageSize(+event.target.value);
    }

    private isRowSelected(rowData: any): boolean {
        let selected: boolean = false;
        this.props.adapter.selectedRow.forEach((item: any, index: number) => {
            if (this.props.adapter.isSame(rowData, item))
                selected = true;
        });
        return selected;
    }
}

export default withTheme(JtDataTable);
