
export enum EResultCode {
	SUCCESS="SUCCESS",
	FAILED="FAILED"
}

export default class ApiResponse {
	errorCode?: number;
	message?: string;
	data: any;
	resultCode:EResultCode=EResultCode.SUCCESS;
}
